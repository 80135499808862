import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Container,
  Heading,
  HStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  Tab,
  Button,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Center,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Popover,
  MenuItem,
  Flex,
  Icon,
  PopoverTrigger,
  PopoverContent,
  Portal,
  PopoverArrow,
  PopoverBody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Box,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Switch,
  AspectRatio,
  ModalHeader,
  ModalCloseButton,
  Select,
  Link,
  Checkbox,
  CheckboxGroup,
  useToast,
  Spinner,
  FormHelperText,
  Grid,
  GridItem,
  Tooltip,
} from "@chakra-ui/react";
import { Select as ChakraSelectReact } from "chakra-react-select";
import {
  AddIcon,
  ChevronRightIcon,
  EditIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import {
  FiPrinter,
  FiMoreVertical,
  FiEdit,
  FiPocket,
  FiTrash2,
  FiClock,
  FiFile,
  FiFileText,
  FiUpload,
  FiUserX,
  FiCheckCircle,
  FiLayers,
  FiPenTool,
  FiFilter,
} from "react-icons/fi";
import { AiOutlineFileExcel } from "react-icons/ai";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import { GiBootKick, GiSpikyExplosion } from "react-icons/gi";
import { SiGoogleclassroom } from "react-icons/si";
import { MdOutlineDocumentScanner } from "react-icons/md";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ReactTable,
  ModalComponent,
  ChakraAlertDialog,
  AlertPop,
  ErrorsDialog,
  Excel,
  WriteExcel,
} from "../../../components";
import { useForm } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import print from "print-js";
import "../../../assets/css/custom.css";
import QRCode from "qrcode";
import { format } from "date-fns";
import SummTable from "../../../components/table/trainee-summary-table";
import { T3Form } from "../../../components/table/t3-form";
import html2canvas from "html2canvas";
import {
  URL_LOCAL,
  URL_PUNCHIN,
  URL_PUNCHOUT,
  URL_STAGING,
} from "../../../constant";
import * as XLSX from "xlsx";
import axios from "axios";
import ThemeContext from "../../../functions/ThemeContext";
import { findPostcode, getStates } from "malaysia-postcodes";

const ref = React.createRef();
const StylesTrainee = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :hover {
        background-color: #ebebeb;
      }
      border-bottom: 1px solid #bdbdbd;
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :first-child {
        width: 5%;
      }
      :nth-child(2) {
        width: 350px;
      }
      :nth-child(3) {
        width: 250px;
      }
    }

    td {
      padding: 0.5rem;
      :last-child {
        margin-top: 20px;
      }
      :nth-child(3) {
        text-align: left;
      }
      :nth-child(4) {
        text-align: left;
      }
      :nth-child(5) {
        text-align: left;
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :first-child {
        width: 5%;
      }
      :nth-child(1) {
        width: 50px;
      }
      :nth-child(2) {
        width: 250px;
      }
      :nth-child(3) {
        width: 150px;
      }
      :nth-child(4) {
        width: 150px;
      }
    }

    td {
      padding: 0.5rem;
      :last-child {
        margin-top: 20px;
      }
    }
  }
`;

const listItem = [
  { name: "Edit", icon: FiEdit },
  { name: "Invoice", icon: FiFileText },
  { name: "Receipt", icon: FiFile },
  { name: "HRDC", icon: FiPocket },
  { name: "Attendance", icon: FiClock },
  { name: "Move To", icon: BiTransfer },
  { name: "Register", icon: FiPenTool },
  { name: "Join", icon: FiCheckCircle },
  { name: "Withdraw", icon: FiUserX },
  { name: "Kick", icon: GiBootKick },
  { name: "Delete", icon: FiTrash2 },
  { name: "Destroy", icon: GiSpikyExplosion },
];

const trainingModes = [
  "Fulltime online inhouse",
  "Fulltime physical inhouse",
  "Parttime online inhouse",
  "Parttime physical inhouse",
  "Fulltime online public",
  "Fulltime physical public",
  "Parttime online public",
  "Parttime physical public",
];

function TraineeAdmin() {
  const [init, setInit] = useState(true);
  const [bigData, setBigData] = useState([]);
  const [printForm, setPrintForm] = useState(false);
  const [data, setData] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [companies, setCompanyList] = useState([]);
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyID, setCompanyID] = useState();
  const [editData, setEditData] = useState([]);
  const [content, setContent] = useState("");
  const [traineeID, setTraineeID] = useState();
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowAddress, setAllowAddress] = useState(false);
  const [template, setTemplate] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [t3, setT3] = useState(false);
  const [datepick, setDatePick] = useState("");
  const [showFooter, setShowFooter] = useState(true);
  const addTrainee = useDisclosure();
  const editTrainee = useDisclosure();
  const deleteMenu = useDisclosure();
  const deleteWithdrawMenu = useDisclosure();
  const kickTraineeMenu = useDisclosure();
  const joinMenu = useDisclosure();
  const withdrawMenu = useDisclosure();
  const registerMenu = useDisclosure();
  const mailHRDF = useDisclosure();
  const invoiceDetail = useDisclosure();
  const receiptDetail = useDisclosure();
  const moveTraining = useDisclosure();
  const modalPunchinOut = useDisclosure();
  const groupModal = useDisclosure();
  const viewModal = useDisclosure();
  const loading = useDisclosure();
  const classroomModal = useDisclosure();
  const generateModal = useDisclosure();
  const companySelect = useDisclosure();
  let nav = useNavigate();
  const { state } = useLocation();
  const [trainingID, setTrainingID] = useState(state.id);
  const [programID] = useState(state.program_id);
  const [excel, setExcel] = useState(false);
  const [qr, setQr] = useState("");
  const [punch, setPunch] = useState();
  const [trainingNames, setTrainingNames] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const [dateRange, setDateRange] = useState([]);
  const [summary, setSummary] = useState([]);
  const [showAddress, setShowAddress] = useState([
    {
      address: "",
      postcode: "",
      state: "",
    },
  ]);
  const downloadRef = React.useRef();
  const [changeCompany, setChangeCompany] = useState(false);
  const [changePhone, setChangePhone] = useState(false);
  const [newCompany, setNewCompany] = useState("Select new company");
  const [promoCode, setPromoCode] = useState([]);
  const [registerStudent, setRegisterStudent] = useState(true);
  const [deleteStudent, setDeleteStudent] = useState(false);
  const [err, setErr] = useState({
    name: false,
    ic: false,
    phone: false,
    email: false,
    address: false,
    postcode: false,
    state: false,
  });
  const errorProps = useDisclosure();
  const [errorAlert, setErrorAlert] = useState("");
  const [trainingFee, setTrainingFee] = useState(0);
  const [compArr, setCompArr] = useState([]);
  const toast = useToast();
  const [viewData, setViewData] = useState();
  const [buttonType, setButtonType] = useState("");
  const [modalData, setModalData] = useState();
  const [groupModalData, setGroupModalData] = useState();
  const [invNum, setInvNum] = useState();
  const [receiptDisplay, setReceiptDisplay] = useState({
    receiptNum: "",
    receiptAmount: "",
  });
  const [groupType, setGroupType] = useState("");
  const themeCtx = useContext(ThemeContext);
  const countryCodes = require("country-codes-list");
  const [countries, setCountries] = useState([]);
  const [MyStates, setMyStates] = useState([]);
  const [tab, setTab] = useState("Register");
  const [summaryComp, setSummaryComp] = useState([]);

  useEffect(() => {
    if (init) {
      setTableLoader(true);
      fetchData();
      fetchCompany();
      fetchCountry();
      fetchTemplate();
      setInit(false);
    }
  }, []);

  const fetchData = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}registeredtrainee/training/${state.id}`
    );
    if (res) {
      if (JSON.parse(res.training_fee)[0]) {
        setTrainingFee(JSON.parse(res.training_fee)[0]);
      } else {
        setTrainingFee(parseInt(res.training_fee));
      }
      setData(res.trainees);
      if (res.discount.length > 0) {
        setDiscount(res.discount);
        let promoList = [];
        for (let i = 0; i < res.discount.length; i++) {
          if (
            new Date(res.discount[i].disc_end).getTime() > new Date().getTime()
          ) {
            let usage;
            if (
              res.discount[i].disc_usage - res.discount[i].disc_curr_usage <
              0
            ) {
              usage = 0;
            } else {
              usage =
                res.discount[i].disc_usage - res.discount[i].disc_curr_usage;
            }
            promoList.push({
              value: res.discount[i].disc_id,
              label:
                res.discount[i].disc_code +
                " (" +
                usage +
                "/" +
                res.discount[i].disc_usage +
                " usages)",
              quota: res.discount[i].disc_usage,
              usage: usage,
            });
          }
        }
        console.log(promoList);
        setPromoCode(promoList);
      }
      // res.discount && setDiscount(res.discount);
      setTableLoader(false);
    }
  };

  const fetchCompany = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}companyname`);
    if (res) {
      let companyList = [];
      for (let i = 0; i < res.length; i++) {
        companyList.push({
          value: JSON.stringify({
            id: res[i].company_id,
            address: res[i].company_address,
          }),
          label: res[i].company_name,
        });
      }
      setCompanyList(companyList);
    }
  };

  const fetchCountry = async () => {
    let listName = countryCodes.customList("countryNameEn", "{countryCode}");
    let listCode = countryCodes.customList(
      "countryNameEn",
      "+{countryCallingCode}"
    );
    let key = Object.keys(listName);
    let name = Object.values(listName);
    let code = Object.values(listCode);
    let tempList = [];
    for (let i = 0; i < key.length; i++) {
      tempList.push({
        value: key[i],
        label: key[i],
        name: name[i],
        code: code[i],
      });
    }
    setCountries(tempList);
    setMyStates(getStates);
  };

  const fetchTemplate = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}template`);
    if (res.length > 0) {
      setTemplate(res);
    }
  };

  const checkNation = (input) => {
    return findPostcode(input).found;
  };

  const checkPostcodes = (input) => {
    return findPostcode(input).city;
  };

  const handleTraineeAddress = (e) => {
    if (e.target.id === "postcode") {
      if (findPostcode(e.target.value).found) {
        resetField("traineeCity", {
          defaultValue: findPostcode(e.target.value).city,
        });
        resetField("traineeState", {
          defaultValue: findPostcode(e.target.value).state,
        });
        resetField("traineeCountry", {
          defaultValue: "Malaysia",
        });
      } else {
        resetField("traineeCity", {
          defaultValue: "",
        });
        resetField("traineeState", {
          defaultValue: "",
        });
        resetField("traineeCountry", {
          defaultValue: "Malaysia",
        });
      }
    }
  };

  const handleCompany = (e) => {
    if (JSON.parse(e.value).id === 1) {
      setCompanyID(1);
      resetField("traineeAddress", {
        defaultValue: "",
      });
      resetField("traineePostcode", {
        defaultValue: "",
      });
      resetField("traineeCity", {
        defaultValue: "",
      });
      resetField("traineeState", {
        defaultValue: "",
      });
      resetField("traineeCountry", {
        defaultValue: "Malaysia",
      });
      setAllowAddress(false);
    } else {
      resetField("traineeAddress", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].address,
      });
      resetField("traineePostcode", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].postcode,
      });
      if (JSON.parse(JSON.parse(e.value).address)[0].city) {
        resetField("traineeCity", {
          defaultValue: JSON.parse(JSON.parse(e.value).address)[0].city,
        });
      } else {
        resetField("traineeCity", {
          defaultValue: checkPostcodes(
            JSON.parse(JSON.parse(e.value).address)[0].postcode
          ),
        });
      }
      resetField("traineeState", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].state,
      });
      if (JSON.parse(JSON.parse(e.value).address)[0].country) {
        resetField("traineeCountry", {
          defaultValue: JSON.parse(JSON.parse(e.value).address)[0].country,
        });
      }
      setErr((err) => ({
        ...err,
        address: false,
        postcode: false,
        city: false,
        state: false,
        country: false,
      }));
      setCompanyID(JSON.parse(e.value).id);
      setCompanyAddress(JSON.parse(JSON.parse(e.value).address));
      setAllowAddress(true);
    }
  };

  const editCompany = (e) => {
    if (JSON.parse(e.value).id === 1) {
      setCompanyID(1);
      setNewCompany(e.label);
      resetField("traineePosition", {
        defaultValue: "Self-Employed",
      });
      resetField("traineeAddress", {
        defaultValue: "",
      });
      resetField("traineePostcode", {
        defaultValue: "",
      });
      resetField("traineeCity", {
        defaultValue: "",
      });
      resetField("traineeState", {
        defaultValue: "",
      });
      resetField("traineeCountry", {
        defaultValue: "",
      });
      setAllowAddress(false);
    } else {
      setCompanyID(JSON.parse(e.value).id);

      resetField("traineePosition", {
        defaultValue: "",
      });
      resetField("traineeAddress", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].address,
      });
      resetField("traineePostcode", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].postcode,
      });
      if (JSON.parse(JSON.parse(e.value).address)[0].city) {
        resetField("traineeCity", {
          defaultValue: JSON.parse(JSON.parse(e.value).address)[0].city,
        });
      } else {
        resetField("traineeCity", {
          defaultValue: checkPostcodes(
            JSON.parse(JSON.parse(e.value).address)[0].postcode
          ),
        });
      }
      resetField("traineeState", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].state,
      });
      if (JSON.parse(JSON.parse(e.value).address)[0].country) {
        resetField("traineeCountry", {
          defaultValue: JSON.parse(JSON.parse(e.value).address)[0].country,
        });
      } else {
        checkNation(JSON.parse(JSON.parse(e.value).address)[0].country) &&
          resetField("traineeCountry", {
            defaultValue: "Malaysia",
          });
      }
      setErr((err) => ({
        ...err,
        address: false,
        postcode: false,
        city: false,
        state: false,
        country: false,
      }));
      setCompanyAddress(JSON.parse(JSON.parse(e.value).address));
      setNewCompany(e.label);
      setAllowAddress(true);
    }
  };

  const handleDownloadImage = async () => {
    const element = downloadRef.current;
    const canvas = await html2canvas(element);

    const img_data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = img_data;
      link.download =
        punch === "in"
          ? state.name + " (PUNCH IN)"
          : // eslint-disable-next-line no-useless-concat
            state.name + " (PUNCH OUT)" + ".jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(img_data);
    }
  };

  const handleFilterSummary = async () => {
    let combinetrainees = [];
    const res = await CRUDFunction.get(
      `${URL_STAGING}traineeattendetails/multiplecomp/${summaryComp}/training/${state.id}`
    );
    if (res) {
      for (let i = 0; i < res.trainees.length; i++) {
        for (let j = 0; j < res.trainees[i].length; j++) {
          combinetrainees.push(res.trainees[i][j]);
        }
      }
      res.trainees = combinetrainees;
      setTableLoader(true);
      traineeSummary(res);
    }
  };

  const { register, handleSubmit, reset, resetField } = useForm();

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
      errorProps.onClose();
    }, 5000);
  };

  const resetErr = () => {
    setErr({
      name: false,
      ic: false,
      phone: false,
      email: false,
      address: false,
      postcode: false,
      state: false,
    });
  };

  const clearErr = (e) => {
    if (e.target.value !== "") {
      if (e.target.id === "name") {
        setErr((err) => ({ ...err, name: false }));
      }
      if (e.target.id === "position") {
        setErr((err) => ({ ...err, position: false }));
      }
      if (e.target.id === "ic") {
        setErr((err) => ({ ...err, ic: false }));
      }
      if (e.target.id === "phone") {
        setErr((err) => ({ ...err, phone: false }));
      }
      if (e.target.id === "email") {
        setErr((err) => ({ ...err, email: false }));
      }
      if (e.target.id === "address") {
        setErr((err) => ({ ...err, address: false }));
      }
      if (e.target.id === "postcode") {
        setErr((err) => ({ ...err, postcode: false }));
      }
      if (e.target.id === "city") {
        setErr((err) => ({ ...err, postcode: false }));
      }
      if (e.target.id === "state") {
        setErr((err) => ({ ...err, state: false }));
      }
      if (e.target.id === "country") {
        setErr((err) => ({ ...err, postcode: false }));
      }
    }
  };

  const onSubmit = async (formInput) => {
    try {
      if (formInput) {
        if (formInput.traineeAddress === "") {
          setErr((err) => ({ ...err, address: true }));
        } else {
          setErr((err) => ({ ...err, address: false }));
        }

        if (formInput.traineePosition === "") {
          setErr((err) => ({ ...err, position: true }));
        } else {
          setErr((err) => ({ ...err, position: false }));
        }

        if (formInput.traineeEmail === "") {
          setErr((err) => ({ ...err, email: true }));
        } else {
          setErr((err) => ({ ...err, email: false }));
        }

        if (formInput.traineeIC === "") {
          setErr((err) => ({ ...err, ic: true }));
        } else {
          setErr((err) => ({ ...err, ic: false }));
        }

        if (formInput.traineeName === "") {
          setErr((err) => ({ ...err, name: true }));
        } else {
          setErr((err) => ({ ...err, name: false }));
        }

        if (formInput.traineePhone === "") {
          setErr((err) => ({ ...err, phone: true }));
        } else {
          setErr((err) => ({ ...err, phone: false }));
        }

        if (formInput.traineePostcode === "") {
          setErr((err) => ({ ...err, postcode: true }));
        } else {
          setErr((err) => ({ ...err, postcode: false }));
        }

        if (formInput.traineeState === "") {
          setErr((err) => ({ ...err, state: true }));
        } else {
          setErr((err) => ({ ...err, state: false }));
        }

        if (formInput.traineeCountry === "") {
          setErr((err) => ({ ...err, state: true }));
        } else {
          setErr((err) => ({ ...err, state: false }));
        }
        // setLoader(true);
        const formData = new FormData();
        formData.append("training_id", state.id);
        formData.append("program_id", programID);
        if (editTrainee.isOpen) {
          if (changeCompany === true) {
            formData.append("company_id", companyID);
          } else {
            formData.append("company_id", editData.company.company_id);
          }
        } else {
          formData.append("company_id", companyID);
        }
        let combineAddress = [
          {
            address: formInput.traineeAddress,
            postcode: formInput.traineePostcode,
            city: formInput.traineeCity,
            state: formInput.traineeState,
            country: formInput.traineeCountry,
          },
        ];
        if (formData.get("company_id") > 1) {
          formData.append("trainee_address", JSON.stringify(showAddress));
        } else {
          formData.append("trainee_address", JSON.stringify(combineAddress));
        }
        if (addTrainee.isOpen || editTrainee.isOpen) {
          if (companyID === 1) {
            formData.append("trainee_position", "Self-Employed");
          } else {
            if (
              formInput.traineePosition === "" ||
              formInput.traineePosition === undefined
            ) {
              formData.append("trainee_position", "-");
            } else {
              formData.append("trainee_position", formInput.traineePosition);
            }
          }

          formData.append("trainee_name", formInput.traineeName);
          formData.append("trainee_email", formInput.traineeEmail);
          formInput.traineeHRDF
            ? formData.append("trainee_hrdf", 1)
            : formData.append("trainee_hrdf", 0);
          formData.append("trainee_allergies", formInput.traineeAllergies);
          formData.append("trainee_referrer", formInput.traineeReferrer);
          formData.append("trainee_payment", formInput.traineePayment);
          formData.append("trainee_ic", formInput.traineeIC.toString());
          if (addTrainee.isOpen) {
            formData.append(
              "trainee_phone",
              formInput.traineeCodePhone + formInput.traineePhone.toString()
            );
          } else {
            if (!changePhone) {
              formData.append("trainee_phone", editData.trainee_phone);
            } else {
              formData.append(
                "trainee_phone",
                formInput.traineeCodePhone + formInput.traineePhone.toString()
              );
            }
          }
          if (formInput.discID) {
            formData.append("disc_id", formInput.discID);
          }
        }
        let res;
        if (addTrainee.isOpen) {
          if (excel) {
            let traineeName = [],
              traineeIC = [],
              traineePhone = [],
              traineeEmail = [],
              traineeAddress = [],
              traineePostcode = [],
              traineeState = [],
              traineeCity = [],
              traineeCountry = [],
              combineAddress = [],
              traineePayment = [],
              traineePosition = [];
            for (let i = 0; i < bigData.length; i++) {
              traineeName.push(bigData[i]["NAMA"]);
              traineeIC.push(bigData[i]["NO KAD PENGENALAN"]);
              traineePhone.push(bigData[i]["NOMBOR TELEFON"]);
              traineeEmail.push(bigData[i]["ALAMAT EMEL"]);
              traineeAddress.push(bigData[i]["ALAMAT BERMASTAUTIN DI JOHOR"]);
              traineePostcode.push(bigData[i]["POSKOD"]);
              traineeCity.push(bigData[i]["BANDAR"]);
              traineeState.push(bigData[i]["NEGERI"]);
              traineeCountry.push(
                bigData[i]["NEGARA"].charAt(0).toUpperCase() +
                  bigData[i]["NEGARA"].slice(1).toLowerCase()
              );
              traineePayment.push(bigData[i]["PAYMENT"]);
              traineePosition.push(bigData[i]["POSITION"]);
            }
            for (let i = 0; i < traineeAddress.length; i++) {
              combineAddress[i] = JSON.stringify([
                {
                  address: traineeAddress[i],
                  postcode: traineePostcode[i],
                  city: traineeCity[i],
                  state: traineeState[i],
                  country: traineeCountry[i],
                },
              ]);
            }
            const formData = new FormData();
            formData.append("trainee_name", JSON.stringify(traineeName));
            formData.append("trainee_ic", JSON.stringify(traineeIC));
            formData.append("trainee_phone", JSON.stringify(traineePhone));
            formData.append("trainee_email", JSON.stringify(traineeEmail));
            formData.append("trainee_address", JSON.stringify(combineAddress));
            formData.append("trainee_payment", JSON.stringify(traineePayment));
            formData.append(
              "trainee_position",
              JSON.stringify(traineePosition)
            );
            if (companyID) {
              formData.append("company_id", companyID);
            }
            formData.append("training_id", state.id);
            res = await CRUDFunction.create(
              `${URL_STAGING}traineeExcel/${trainingID}`,
              formData
            );
          } else {
            res = await CRUDFunction.create(`${URL_STAGING}trainee`, formData);
          }

          if (res.errors || res.message) {
            setErrorAlert(res.message);
            errorProps.onOpen();
            timer();
          } else {
            setExcel(false);
            setData(res.trainees);
            setCompanyID("");
            resetField("traineeAddress", {
              defaultValue: "",
            });
            resetField("traineePostcode", {
              defaultValue: "",
            });
            resetField("traineeState", {
              defaultValue: "",
            });
            setAllowAddress(false);
            reset();
            resetErr();
            setAlert({
              ...alert,
              class: "show",
              status: "success",
              message: "Data is successfully created.",
            });
            timer();
            addTrainee.onClose();
          }
        } else if (editTrainee.isOpen) {
          const res = await CRUDFunction.update(
            `${URL_STAGING}trainee/update/${traineeID}`,
            formData
          );

          if (res.errors) {
            setErrorAlert(res.message);
            errorProps.onOpen();
            timer();
          } else {
            setExcel(false);
            setData(res.trainees);
            reset();
            resetErr();
            setNewCompany("");
            setChangePhone(false);
            setChangeCompany(false);
            setAlert({
              ...alert,
              class: "show",
              status: "success",
              message: "Data is successfully updated.",
            });
            timer();
            editTrainee.onClose();
          }
        } else if (invoiceDetail.isOpen) {
          setLoader(false);
          const formData = new FormData();
          if (formInput.invoiceNum) {
            formData.append("inv_num", formInput.invoiceNum);
          } else {
            if (invNum !== 0) {
              formData.append("inv_num", invNum);
            }
          }
          formData.append("inv_date", formInput.invoiceDate);
          formData.append("trainee_id", traineeID);
          formData.append("training_id", trainingID);
          if (promoCode) {
            let discList = [];
            for (let i = 0; i < promoCode.length; i++) {
              discList.push(promoCode[i].value);
            }
            formData.append("disc_id", discList);
          }

          if (formData.get("inv_num") && formData.get("inv_date")) {
            if (buttonType === "View") {
              loading.onOpen();
              const res2 = await CRUDFunction.update(
                `${URL_STAGING}invoice/view`,
                formData
              );
              if (res2) {
                loading.onClose();
                setModalData(formData);
                setViewData(res2);
                viewModal.onOpen();
              }
            }
          } else {
            toast({
              title: "Invoice error",
              description: "Enter invoice number and select invoice date",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        } else if (receiptDetail.isOpen) {
          setLoader(false);
          const formData = new FormData();
          if (formInput.receiptNum) {
            formData.append("receipt_num", formInput.receiptNum);
          } else {
            if (receiptDisplay.receiptNum !== "") {
              formData.append("receipt_num", receiptDisplay.receiptNum);
            }
          }
          if (formInput.receiptAmount) {
            formData.append("receipt_amount", formInput.receiptAmount);
          } else {
            if (receiptDisplay.receiptAmount !== "") {
              formData.append("receipt_amount", receiptDisplay.receiptAmount);
            }
          }
          formData.append("receipt_date", formInput.receiptDate);
          formData.append("trainee_id", traineeID);
          formData.append("training_id", trainingID);

          if (formData.get("receipt_num") && formData.get("receipt_amount")) {
            if (buttonType === "View") {
              loading.onOpen();
              const res = await CRUDFunction.update(
                `${URL_STAGING}receipt/view`,
                formData
              );
              if (res) {
                loading.onClose();
                setModalData(formData);
                setViewData(res);
                viewModal.onOpen();
              }
            }
          } else {
            toast({
              title: "Receipt error",
              description: "Enter receipt number and select receipt date",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        } else if (moveTraining.isOpen) {
          const formData = new FormData();
          formData.append("training_id_new", formInput.trainingID);
          formData.append("company_id", companyID);
          formData.append("training_id_old", trainingID);

          const res = await CRUDFunction.update(
            `${URL_STAGING}trainee/moveto/${traineeID}`,
            formData
          );

          if (res) {
            reset();
            setLoader(false);
            setData(res.trainees);
            moveTraining.onClose();
          }
        }
      }
    } catch (err) {
      console.log(err);
      moveTraining.onClose();
      receiptDetail.onClose();
      editTrainee.onClose();
      addTrainee.onClose();
      invoiceDetail.onClose();
      setLoader(false);
    }
  };

  const groupSubmit = async (formInput) => {
    const formData = new FormData();
    // GROUP INVOICE & RECEIPT AGREEMENT----------------------------------------------------------------------------------
    if (formInput.groupModalCheckbox.length > 1) {
      const arrTrainee = [];
      for (let i = 0; i < formInput.groupModalCheckbox.length; i++) {
        arrTrainee.push(formInput.groupModalCheckbox[i]);
      }
      const res1 = await CRUDFunction.get(
        `${URL_STAGING}trainee/${formInput.groupModalCheckbox[0]}/${state.id}`
      );
      if (res1.company.company_id !== 1) {
        formData.append("companyName", res1.company.company_name);
        formData.append("companyAddress", res1.company.company_address);
        formData.append("picName", res1.company.company_picname);
        formData.append("picNo", res1.company.company_picphone);
        formData.append("picEmail", res1.company.company_picemail);
        formData.append("trainingID", state.id);
        formData.append("quantity", formInput.groupModalCheckbox.length);
        formData.append("traineesID", arrTrainee);
        if (groupType === "invoice") {
          formData.append("invNo", formInput.groupInvNo);
          formData.append("invDate", formInput.groupInvDate);
        } else if (groupType === "receipt") {
          formData.append("receiptNo", formInput.groupReceiptNo);
          formData.append("receiptDate", formInput.groupReceiptDate);
          formData.append("receiptAmount", formInput.groupReceiptAmount);
        }
      }
      if (promoCode) {
        let discList = [];
        for (let i = 0; i < promoCode.length; i++) {
          discList.push(promoCode[i].value);
        }
        formData.append("disc_id", discList);
      }
      if (buttonType === "view") {
        loading.onOpen();
        let res2;
        if (groupType === "invoice") {
          res2 = await CRUDFunction.update(
            `${URL_STAGING}invoice/viewGroup`,
            formData
          );
          if (res2) {
            loading.onClose();
            setGroupModalData(formData);
            setViewData(res2);
            viewModal.onOpen();
          }
        } else if (groupType === "receipt") {
          res2 = await CRUDFunction.update(
            `${URL_STAGING}receipt/viewGroup`,
            formData
          );
          if (res2.errors) {
            loading.onClose();
            toast({
              title: "Invoice error",
              description: res2.errors,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            loading.onClose();
            setGroupModalData(formData);
            setViewData(res2);
            viewModal.onOpen();
          }
        }
      }
    } else {
      toast({
        title: "Error",
        description: "Select more than 1 trainee for group invoice or receipt",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const generateSubmit = async (formInput) => {
    const formData = new FormData();

    console.log(formInput);
    const arrTrainee = [];
    let res1;

    if (data.length === 1) {
      arrTrainee.push(formInput.groupModalCheckbox);
      res1 = await CRUDFunction.get(
        `${URL_STAGING}trainee/${formInput.groupModalCheckbox}/${state.id}`
      );
      formData.append("quantity", 1);
    } else {
      for (let i = 0; i < formInput.groupModalCheckbox.length; i++) {
        arrTrainee.push(formInput.groupModalCheckbox[i]);
      }
      res1 = await CRUDFunction.get(
        `${URL_STAGING}trainee/${formInput.groupModalCheckbox[0]}/${state.id}`
      );
      formData.append("quantity", formInput.groupModalCheckbox.length);
    }

    //check company id
    if (res1.company.company_id === 1) {
      formData.append("companyName", res1.trainee_name);
      formData.append("companyAddress", res1.trainee_address);
    } else {
      formData.append("companyName", res1.company.company_name);
      formData.append("companyAddress", res1.company.company_address);
    }

    //check any discount among the trainees
    let arrDiscount = [];
    for (let i = 0; i < arrTrainee.length; i++) {
      const trainee = await CRUDFunction.get(
        `${URL_STAGING}trainee/${arrTrainee[i]}/${state.id}`
      );
      if (trainee.trainee_discount) {
        arrDiscount.push(trainee.trainee_discount);
      } else {
        arrDiscount.push("");
      }
    }

    formData.append("traineeDiscountID", arrDiscount);
    formData.append("trainingID", state.id);
    formData.append("traineesID", arrTrainee);

    const res2 = await CRUDFunction.create(
      `${URL_STAGING}template/generate`,
      formData
    );
    if (res2 && groupType === selectedTemplate.title) {
      let temp = selectedTemplate.config;
      if (formInput.approvalNo && formInput.employerCode) {
        Object.keys(temp).map((key) => {
          if (key === "Company Name") {
            temp[key].sample = res2.compName;
          } else if (key === "Company Address") {
            temp[key].sample = res2.compAddress;
          } else if (key === "Employer Code") {
            temp[key].sample = formInput.employerCode;
          } else if (key === "Approval No") {
            temp[key].sample = formInput.approvalNo;
          } else if (key === "Course Title") {
            temp[key].sample = res2.courseName;
          } else if (key === "Start Date") {
            temp[key].sample = res2.startDate;
          } else if (key === "End Date") {
            temp[key].sample = res2.endDate;
          } else if (key === "Training Venue") {
            temp[key].sample = res2.venue;
          } else if (key === "Pax") {
            temp[key].sample = res2.noPax;
          } else if (key === "Fee Approved") {
            temp[key].sample = res2.totalFees;
          } else if (key === "Fee Claimed") {
            temp[key].sample = res2.totalFees;
          }
        });
      } else if (Object.keys(temp).includes("Depo")) {
        Object.keys(temp).map((key) => {
          if (key === "Company Name") {
            temp[key].sample = res2.compName;
          } else if (key === "Company Address") {
            temp[key].sample = res2.compAddress;
          } else if (key === "Training Name") {
            temp[key].sample = res2.trainingName;
          } else if (key === "Start Date") {
            temp[key].sample = res2.startDate;
          } else if (key === "End Date") {
            temp[key].sample = res2.endDate;
          } else if (key === "Training Time") {
            temp[key].sample = res2.trainingTime;
          } else if (key === "Training Place") {
            temp[key].sample = res2.trainingPlace;
          } else if (key === "Fee") {
            temp[key].sample = res2.trainingFee;
          } else if (key === "Pax") {
            temp[key].sample = res2.noPax;
          } else if (key === "Total Fee") {
            temp[key].sample = res2.totalFees;
          } else if (key === "Depo") {
            temp[key].sample = res2.totalFees * 0.5;
          } else if (key === "Dateline") {
            temp[key].sample = res2.dateline;
          }
        });
      } else {
        Object.keys(temp).map((key) => {
          if (key === "Company Name") {
            temp[key].sample = res2.compName;
          } else if (key === "Company Name 2") {
            temp[key].sample = res2.compName + ".";
          } else if (key === "Company Address") {
            temp[key].sample = res2.compAddress;
          } else if (key === "Course Title") {
            temp[key].sample = res2.courseName;
          } else if (key === "Training Date") {
            temp[key].sample = res2.trainingDate;
          } else if (key === "No. of pax") {
            temp[key].sample = res2.noPax;
          } else if (key === "No. of days") {
            temp[key].sample = res2.noDays;
          } else if (key === "Total Fees") {
            temp[key].sample = res2.totalFees;
          }
        });
      }
      selectedTemplate.config = temp;
      console.log("selectedTemplate", selectedTemplate);
      let newWindow = window.open("/templateScreen");
      newWindow.type = "generate";
      newWindow.activeKey = selectedTemplate;
    }
  };

  const classroomSubmit = async (formInput) => {
    if (
      formInput.classroomEnrollCode !== "" &&
      formInput.hcPackageClassroom !== ""
    ) {
      let crtrainees = [];
      data.map((item, index) => {
        let formattedIc = item.trainee_ic;
        if (item.trainee_ic.length === 12) {
          formattedIc = `${item.trainee_ic.substring(
            0,
            6
          )}-${item.trainee_ic.substring(6, 8)}-${item.trainee_ic.substring(
            8
          )}`;
        }
        crtrainees[index] = {
          name: item.trainee_name.toUpperCase(),
          email: item.trainee_name,
          icNo: formattedIc,
        };
      });
      let courseName = state.course_name;
      if (state.course_id === 9) {
        switch (formInput.hcPackageClassroom) {
          case "a":
            courseName =
              "Halal Competency Program (Package A - Shariah&Fatwa and Malaysian Standard)";
            break;
          case "b":
            courseName = "Halal Competency Program (Package B - MPPHM & MHMS)";
            break;
          case "c":
            courseName =
              "Halal Competency Program (Package C - Halal Legislation and Critical Ingredient)";
            break;
          case "d":
            courseName = "Public Halal Competency Program (MS 1500:2019)";
            break;
          default:
            courseName = state.course_name;
        }
      }
      let crRawData = {
        secret: "HL2023PSS",
        courseName: courseName,
        trainName: state.name,
        enrollCode: formInput.classroomEnrollCode,
        startDate: format(new Date(state.date[0].startDate), "yyyyMMdd"),
        endDate: format(
          new Date(state.date[state.date.length - 1].endDate),
          "yyyyMMdd"
        ),
        trainees: crtrainees,
      };
      axios
        .post(
          `https://classroom.holisticslab.my/api/autocreateclass`,
          crRawData,
          { headers: {} }
        )
        .then(({ data }) => {
          if (data.isSuccess === true) {
            toast({
              title: "Classroom is created!",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            classroomModal.onClose();
          } else {
            toast({
              title: data.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    } else {
      toast({
        title: "Insert enrol code",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleEdit = useCallback(
    async (name, value) => {
      if (name === "Edit") {
        const res = await CRUDFunction.get(
          `${URL_STAGING}trainee/${value}/${state.id}`
        );
        if (res) {
          setTrainingID(res.training_id);

          setEditData(res);
          if (res.company_id === 1) {
            setShowAddress(JSON.parse(res.trainee_address));
          } else {
            setShowAddress(JSON.parse(res.company.company_address));
          }
          setTraineeID(value);
          editTrainee.onOpen();
        }
      } else if (name === "Invoice") {
        setTraineeID(value);
        const res = await CRUDFunction.get(
          `${URL_STAGING}invoicecheck/${value}/${state.id}`
        );
        if (res) {
          setInvNum(res);
        }
        invoiceDetail.onOpen();
      } else if (name === "Receipt") {
        setTraineeID(value);
        const res = await CRUDFunction.get(
          `${URL_STAGING}invoicecheck/${value}/${state.id}`
        );
        if (res) {
          setInvNum(res);
          const res2 = await CRUDFunction.get(
            `${URL_STAGING}receiptcheck/${value}/${state.id}`
          );
          if (res2) {
            setReceiptDisplay({
              receiptNum: res2.receipt_num,
              receiptAmount: res2.receipt_amount,
            });
          }
          receiptDetail.onOpen();
        } else {
          toast({
            title: "Invoice error",
            description: "No invoice exists for this trainee",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } else if (name === "HRDC") {
        mailHRDF.onOpen();
      } else if (name === "Attendance") {
        return nav("../attendance/individual", {
          replace: false,
          state: {
            trainee_id: value,
            training_id: state.id,
            training_name: state.name,
          },
        });
      } else if (name === "Move To") {
        const res = await CRUDFunction.get(
          `${URL_STAGING}trainingcourse/${state.course_id}`
        );
        if (res) {
          setTraineeID(value.traineeID);
          setCompanyID(value.companyID);
          setTrainingNames(res);
          moveTraining.onOpen();
        }
      } else if (name === "Withdraw") {
        setTraineeID(value);
        withdrawMenu.onOpen();
      } else if (name === "Join") {
        setTraineeID(value);
        joinMenu.onOpen();
      } else if (name === "Register") {
        setTraineeID(value);
        registerMenu.onOpen();
      } else if (name === "Delete") {
        setTraineeID(value);
        deleteMenu.onOpen();
      } else if (name === "Destroy") {
        setTraineeID(value);
        deleteWithdrawMenu.onOpen();
      } else if (name === "Kick") {
        setTraineeID(value);
        kickTraineeMenu.onOpen();
      }
    },
    [
      deleteMenu,
      deleteWithdrawMenu,
      kickTraineeMenu,
      editTrainee,
      invoiceDetail,
      mailHRDF,
      moveTraining,
      nav,
      receiptDetail,
      state.id,
      state.name,
      withdrawMenu,
      registerMenu,
    ]
  );

  const qrCode = (url) => {
    QRCode.toDataURL(
      url,
      {
        width: 500,
        margin: 1,
      },
      (err, url) => {
        if (err) return console.error(err);
        setQr(url);
      }
    );
  };

  const getDatesRange = async (items, courseID) => {
    return new Promise((resolve, reject) => {
      try {
        const dates = [];
        for (let i = 0; i < items.length; i++) {
          let start = items[i].start;
          let end = items[i].end;
          const date = new Date(start.getTime());

          while (date <= end) {
            dates.push(format(new Date(date), "y-LL-dd"));
            date.setDate(date.getDate() + 1);
          }
        }
        if (dates) {
          resolve(dates);
        }
        // const date = new Date(start.getTime());
        // const dates = [];

        // while (date <= end) {
        //   dates.push(format(new Date(date), "y-LL-dd"));
        //   date.setDate(date.getDate() + 1);
        // }
        // let dateFilterred;
        // if (courseID === 3 || courseID === 9) {
        //   dateFilterred = dates.filter((date) => {
        //     return date;
        //   });
        // } else {
        //   dateFilterred = dates.filter((date) => {
        //     return (
        //       new Date(date).getDay() !== 0 &&
        //       new Date(date).getDay() !== 5 &&
        //       new Date(date).getDay() !== 6
        //     );
        //   });
        // }
        // if (dateFilterred) {
        //   resolve(dateFilterred);
        // }
      } catch (err) {
        reject(err);
      }
    });
  };

  const traineeSummary = async (resdata) => {
    setSummary([]);
    setT3(false);
    setDatePick("");
    setShowFooter(true);
    var res = "";
    if (!resdata) {
      setSummaryComp([]);
      res = await CRUDFunction.get(
        `${URL_STAGING}traineeattendetails/training/${state.id}`
      );
    } else {
      res = resdata;
    }

    const res2 = await CRUDFunction.get(
      `${URL_STAGING}company/training/${state.id}`
    );

    if (res2) {
      setCompArr(res2);
    }

    if (res) {
      setDeleteStudent(false);
      var trainingDate = JSON.parse(res.training_date);
      let items = [{ start: "", end: "" }];
      for (let i = 0; i < trainingDate.length; i++) {
        const d1 = new Date(trainingDate[i].startDate);
        const d2 = new Date(trainingDate[i].endDate);
        items[i] = { start: d1, end: d2 };
      }
      const dates = await getDatesRange(items, res.course_id);

      if (dates) {
        setDateRange(dates);
        res.trainees.map((item, index) => {
          //if trainee's attendance data is 0
          if (item.attendance.length === 0) {
            for (let i = 0; i < dates.length; i++) {
              if (!item.attendance[i]) {
                item.attendance.push({
                  attendance_punchin: "--:--",
                });
              }

              item.attendance[i].attendance_punchout = "--:--";
              if (item.pivot.join_status === "3") {
                item.attendance[i].attendance_status = 3;
              }
            }
          } else {
            for (let i = 0; i < dates.length; i++) {
              var temp = "--:--";
              var temp2 = "--:--";

              if (item.attendance[i] !== undefined) {
                if (item.attendance[i].attendance_punchin !== null) {
                  temp = item.attendance[i].attendance_punchin;
                }
                if (item.attendance[i].attendance_punchout !== null) {
                  temp2 = item.attendance[i].attendance_punchout;
                }

                if (!temp.includes(dates[i]) && !temp2.includes(dates[i])) {
                  if (item.pivot.join_status === "3") {
                    item.attendance.splice(i, 0, {
                      attendance_punchin: "--:--",
                      attendance_punchout: "--:--",
                      attendance_status: 3,
                    });
                  } else {
                    item.attendance.splice(i, 0, {
                      attendance_punchin: "--:--",
                      attendance_punchout: "--:--",
                    });
                  }
                } else if (!temp.includes(dates[i])) {
                  item.attendance[i].attendance_punchin = "--:--";
                } else if (!temp2.includes(dates[i])) {
                  item.attendance[i].attendance_punchout = "--:--";
                }
              } else {
                if (item.pivot.join_status === "3") {
                  item.attendance.splice(i, 0, {
                    attendance_punchin: "--:--",
                    attendance_punchout: "--:--",
                    attendance_status: 3,
                  });
                } else {
                  item.attendance.splice(i, 0, {
                    attendance_punchin: "--:--",
                    attendance_punchout: "--:--",
                  });
                }
              }
            }
          }
        });

        setSummary(res);
        setTableLoader(false);
      }
    }
  };

  const NavItem = ({ icon, onClick, children }) => {
    return (
      <MenuItem onClick={onClick} _hover={{ bg: themeCtx.mode && "#1b1b1b" }}>
        <Flex p="2" mx="2" borderRadius="lg" role="group" cursor="pointer">
          {
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "green",
              }}
              as={icon}
            />
          }
          <Text>{children}</Text>
        </Flex>
      </MenuItem>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "Trainee",
        accessor: (data) => {
          return {
            name: data.trainee_name,
            ic: data.trainee_ic,
            position: data.trainee_position,
          };
        },
        Cell: ({ value }) => {
          return (
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                textTransform: "capitalize",
              }}
            >
              <li style={{ textTransform: "capitalize" }}>
                <b>{value.name.toLowerCase()}</b>
              </li>
              <li>{value.ic}</li>
              <li>
                {value.position !== "Self-Employed" ? value.position : ""}
              </li>
            </ul>
          );
        },
      },
      {
        Header: "Company",
        accessor: "company.company_name",
        Cell: ({ value }) => {
          if (value) {
            return (
              <>
                <Text>{value}</Text>
              </>
            );
          } else {
            return (
              <>
                <Text>Self-Employed</Text>
              </>
            );
          }
        },
      },
      {
        Header: "Contact",
        accessor: (data) => {
          return {
            email: data.trainee_email,
            phone: data.trainee_phone,
          };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li>{value.email}</li>
              <li>{value.phone}</li>
            </ul>
          );
        },
      },
      {
        Header: "Training",
        accessor: (data) => {
          let isNation = checkNation(
            JSON.parse(data.trainee_address)[0].postcode
          );
          if (isNation === false) {
            if (
              JSON.parse(data.trainee_address)[0].country === "Malaysia" ||
              JSON.parse(data.trainee_address)[0].country === undefined
            ) {
              isNation = true;
            }
          }
          return {
            isNation: isNation,
            discountlist: discount,
            promo: data.pivot.disc_id,
            allergy: data.trainee_allergies,
            referrer: data.trainee_referrer,
            registerDate: data.pivot.created_at,
          };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          let fee;
          if (typeof trainingFee === "object") {
            if (value.isNation === true) {
              fee = trainingFee.ringgit;
            } else {
              fee = trainingFee.dollar;
            }
          } else {
            fee = trainingFee;
          }
          let discPercent;
          let discCode;
          for (let i = 0; i < value.discountlist.length; i++) {
            if (value.promo === value.discountlist[i].disc_id) {
              discPercent = value.discountlist[i].disc_percent;
              discCode = value.discountlist[i].disc_code;
              break;
            }
          }
          return (
            <ul style={{ listStyleType: "none" }}>
              <li>
                Fee:{" "}
                <b>
                  {value.isNation ? "RM " : "USD "}
                  {value.promo
                    ? Math.round((fee - (fee * discPercent) / 100) * 100) / 100
                    : fee}
                </b>
              </li>
              <li>
                Promo Code: <b>{value.promo ? discCode : "None"}</b>
              </li>
              <li>
                Special Diet / Allergy: <b>{value.allergy}</b>
              </li>
              <li>
                Referrer: <b>{value.referrer}</b>
              </li>
              <li>
                Register Date:{" "}
                <b>
                  {value.registerDate &&
                    format(new Date(value.registerDate), "dd/M/y")}
                </b>
              </li>
            </ul>
          );
        },
      },
      {
        Header: "Payment",
        accessor: (data) => {
          return {
            traineeID: data.trainee_id,
            paymentStatus: data.pivot.payment_status,
          };
        },
        Cell: ({ value }) => {
          return (
            <Select
              defaultValue={
                value.paymentStatus !== 0 ? value.paymentStatus : "1"
              }
              bg={
                value.paymentStatus === "1"
                  ? "orange.400"
                  : value.paymentStatus === "2"
                  ? "yellow.400"
                  : value.paymentStatus === "3"
                  ? "green.400"
                  : "orange.400"
              }
              onChange={async (e) => {
                const formData = new FormData();
                formData.append("trainee_payment", e.target.value);
                formData.append("training_id", state.id);
                const res = await CRUDFunction.update(
                  `${URL_STAGING}trainee/updatePaymentAdmin/${value.traineeID}`,
                  formData
                );
                if (res === 1) {
                  handleTab(tab);
                  setAlert({
                    ...alert,
                    class: "show",
                    status: "success",
                    message: "Payment is updated",
                  });
                  timer();
                }
              }}
            >
              <option value="1">Pending</option>
              <option value="2">Processing</option>
              <option value="3">Completed</option>
            </Select>
          );
        },
      },
      {
        Header: "HRDC",
        accessor: "trainee_hrdf",
        Cell: ({ value }) => {
          return (
            <Text color={value === 1 ? "#1DB700" : "black"}>
              {value === 1 ? (
                <Center>
                  <BsCheckCircleFill color="green" />
                </Center>
              ) : (
                "-"
              )}
            </Text>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: (data) => {
          return {
            traineeID: data.trainee_id,
            companyID: data.company.company_id,
          };
        },
        Cell: ({ value }) => {
          return (
            <Menu>
              <MenuButton
                as={Button}
                my="30px"
                _hover={{ bg: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                _expanded={{ bg: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                _click={{ bg: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                variant="ghost"
                rightIcon={<FiMoreVertical />}
              ></MenuButton>
              <MenuList bgColor={themeCtx.mode && "#2b2b2b"}>
                {listItem.map(({ icon, name, src }) => {
                  if (deleteStudent) {
                    if (
                      name === "Register" ||
                      name === "Destroy" ||
                      name === "Join"
                    ) {
                      return (
                        <NavItem
                          icon={icon}
                          onClick={() => {
                            handleEdit(name, value.traineeID);
                          }}
                        >
                          {name}
                        </NavItem>
                      );
                    } else {
                      return "";
                    }
                  } else if (registerStudent) {
                    if (name === "Join" || name === "Withdraw") {
                      return (
                        <NavItem
                          icon={icon}
                          onClick={() => {
                            handleEdit(name, value.traineeID);
                          }}
                        >
                          {name}
                        </NavItem>
                      );
                    }
                  } else {
                    if (name === "Destroy" || name === "Kick") {
                    } else {
                      if (name === "Move To") {
                        return (
                          <NavItem
                            icon={icon}
                            onClick={() => {
                              handleEdit(name, {
                                traineeID: value.traineeID,
                                companyID: value.companyID,
                              });
                            }}
                          >
                            {name}
                          </NavItem>
                        );
                      } else if (name !== "Join") {
                        return (
                          <NavItem
                            icon={icon}
                            onClick={() => {
                              handleEdit(name, value.traineeID);
                            }}
                          >
                            {name}
                          </NavItem>
                        );
                      }
                    }
                  }
                })}
              </MenuList>
            </Menu>
          );
        },
        disableSortBy: true,
      },
    ],
    [handleEdit, registerStudent, deleteStudent]
  );

  const columnsPrint = React.useMemo(
    () => [
      {
        Header: "#",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "Trainee",
        accessor: (data) => {
          return {
            name: data.trainee_name,
            ic: data.trainee_ic,
            position: data.trainee_position,
          };
        },
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none", textAlign: "left" }}>
              <li>{value.name}</li>
              <li>{value.ic}</li>
              <li>{value.position}</li>
            </ul>
          );
        },
      },
      {
        Header: "Company",
        accessor: "company.company_name",
        Cell: ({ value }) => {
          if (value) {
            return (
              <>
                <Text>{value}</Text>
              </>
            );
          } else {
            return (
              <>
                <Text>Self-Employed</Text>
              </>
            );
          }
        },
      },
      {
        Header: "Contact",
        accessor: (data) => {
          return {
            email: data.trainee_email,
            phone: data.trainee_phone,
          };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li>{value.email}</li>
              <li>{value.phone}</li>
            </ul>
          );
        },
      },
      {
        Header: "Training",
        accessor: (data) => {
          let isNation = checkNation(
            JSON.parse(data.trainee_address)[0].postcode
          );
          if (isNation === false) {
            if (
              JSON.parse(data.trainee_address)[0].country === "Malaysia" ||
              JSON.parse(data.trainee_address)[0].country === undefined
            ) {
              isNation = true;
            }
          }
          return {
            isNation: isNation,
            discountlist: discount,
            promo: data.pivot.disc_id,
            allergy: data.trainee_allergies,
            referrer: data.trainee_referrer,
          };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          let fee;
          if (typeof trainingFee === "object") {
            if (value.isNation === true) {
              fee = trainingFee.ringgit;
            } else {
              fee = trainingFee.dollar;
            }
          } else {
            fee = trainingFee;
          }
          let discPercent;
          let discCode;
          for (let i = 0; i < value.discountlist.length; i++) {
            if (value.promo === value.discountlist[i].disc_id) {
              discPercent = value.discountlist[i].disc_percent;
              discCode = value.discountlist[i].disc_code;
              break;
            }
          }
          return (
            <ul style={{ listStyleType: "none" }}>
              <li>
                Fee:{" "}
                <b>
                  {value.isNation ? "RM " : "USD "}
                  {value.promo ? fee - (fee * discPercent) / 100 : fee}
                </b>
              </li>
              <li>
                Promo Code: <b>{value.promo ? discCode : "None"}</b>
              </li>
              <li>
                Special Diet / Allergy: <b>{value.allergy}</b>
              </li>
              <li>
                Referrer: <b>{value.referrer}</b>
              </li>
            </ul>
          );
        },
      },
      {
        Header: "Payment",
        accessor: "trainee_payment",
        Cell: ({ value }) => {
          return (
            <Text
              color={
                value === "1"
                  ? "orange.400"
                  : value === "2"
                  ? "yellow.400"
                  : value === "3"
                  ? "green.400"
                  : "orange.400"
              }
            >
              {value === "1"
                ? "Pending"
                : value === "2"
                ? "Processing"
                : value === "3"
                ? "Completed"
                : "Pending"}
            </Text>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "HRDC",
        accessor: "trainee_hrdf",
        Cell: ({ value }) => {
          return (
            <Text color={value === 1 ? "#1DB700" : "black"}>
              {value === 1 ? (
                <Center>
                  <BsCheckCircleFill color="green" />
                </Center>
              ) : (
                "-"
              )}
            </Text>
          );
        },
        disableSortBy: true,
      },
    ],
    []
  );

  const WriteExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Responses");
    XLSX.writeFile(wb, "sampleData.export.xlsx", { compression: true });
  };

  const handleTab = useCallback(async (name) => {
    if (name === "Register") {
      setTab("Register");
      setData([]);
      setTableLoader(true);

      const res = await CRUDFunction.get(
        `${URL_STAGING}registeredtrainee/training/${state.id}`
      );
      if (res) {
        setDeleteStudent(false);
        setRegisterStudent(true);
        setData(res.trainees);
        setTableLoader(false);
      }
    } else if (name === "Join") {
      setTab("Join");
      setData([]);
      setTableLoader(true);

      const res = await CRUDFunction.get(
        `${URL_STAGING}joinedtrainee/training/${state.id}`
      );

      if (res) {
        setDeleteStudent(false);
        setRegisterStudent(false);
        setData(res.trainees);
        setTableLoader(false);
      }
    } else if (name === "Withdraw") {
      setTab("Withdraw");
      setData([]);
      setTableLoader(true);
      const res = await CRUDFunction.get(
        `${URL_STAGING}withdrawtrainee/training/${state.id}`
      );
      if (res) {
        setDeleteStudent(true);
        setRegisterStudent(false);
        setData(res.trainees);
        setTableLoader(false);
      }
    }
  }, []);

  return (
    <div>
      {alert.message ? (
        <AlertPop
          width="30%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Breadcrumb
        as={Heading}
        separator={<ChevronRightIcon />}
        m="25px 0px 25px 100px"
        style={{ color: themeCtx.mode && "white", textTransform: "uppercase" }}
      >
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => nav("/training", { replace: false })}>
            <Text>TRAINING</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink w="100%" maxW="500px">
            <Text noOfLines={1}>{state.name}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Box m="25px 100px 25px 100px">
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: "#2b2b2b", color: "white" }}>
                <Box as="span" flex="1" textAlign="left">
                  <b>Training Info</b>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} p="10px">
                <GridItem w="100%" h="auto">
                  <b>Programme Name</b>
                  <Text>{state.fullData.programme.program_name}</Text>
                </GridItem>
                <GridItem w="100%" h="auto">
                  <b>Course Name</b>
                  <Text>{state.course_name}</Text>
                </GridItem>
                <GridItem w="100%" h="auto ">
                  <b>Training Name</b>
                  <Text>{state.name}</Text>
                </GridItem>
                <GridItem w="100%" h="auto ">
                  <b>Cohort</b>
                  <Text>{state.fullData.training_cohort}</Text>
                </GridItem>
              </Grid>
              <Grid templateColumns="repeat(3, 1fr)" gap={6} p="10px">
                <GridItem w="100%" h="auto">
                  <b>Training Place</b>
                  <Text>{state.fullData.training_place}</Text>
                </GridItem>
                <GridItem w="100%" h="auto">
                  {" "}
                  <b>Training Date</b>
                  {state.date[0].startDate === state.date[0].endDate
                    ? state.date.map((item, index) => (
                        <Text>
                          <b>Day {index + 1}: </b>
                          {format(new Date(item.startDate), "dd MMM y")}
                        </Text>
                      ))
                    : state.date.map((item, index) => (
                        <>
                          <Text
                            mt="2px"
                            fontWeight="bold"
                            textDecoration="underline"
                          >
                            Week {index + 1}:
                          </Text>
                          <Text>
                            Start:{" "}
                            {format(new Date(item.startDate), "dd MMM y")}
                          </Text>
                          <Text>
                            End: {format(new Date(item.endDate), "dd MMM y")}
                          </Text>
                        </>
                      ))}
                </GridItem>
                <GridItem w="100%" h="auto">
                  <b>Training Time</b>
                  <Text>
                    {state.time[0]} - {state.time[1]}
                  </Text>
                </GridItem>
                <GridItem w="100%" h="auto">
                  <b>Training Mode</b>
                  <Text>{trainingModes[state.fullData.training_mode]}</Text>
                </GridItem>
                <GridItem w="100%" h="auto">
                  <b>Training Fee</b>
                  {JSON.parse(state.fullData.training_fee)[0] ? (
                    <>
                      <Text>
                        RM{" "}
                        {parseInt(
                          JSON.parse(state.fullData.training_fee)[0].ringgit
                        )}
                      </Text>
                      <Text>
                        USD{" "}
                        {parseInt(
                          JSON.parse(state.fullData.training_fee)[0].dollar
                        )}
                      </Text>
                    </>
                  ) : (
                    <Text>RM {parseInt(state.fullData.training_fee)}</Text>
                  )}
                  {/* <Text>RM {parseInt(state.fullData.training_fee)}</Text> */}
                </GridItem>
                <GridItem w="100%" h="auto">
                  <b>Training Payment Type</b>
                  <Text>{state.fullData.training_payment}</Text>
                </GridItem>
                <GridItem w="100%" h="auto">
                  <b>Training Purchase Type</b>
                  <Text>{state.fullData.training_purchase}</Text>
                </GridItem>
                <GridItem w="100%" h="auto">
                  <b>Training Sponsorhip</b>
                  <Text>{state.fullData.training_sponsorship}</Text>
                </GridItem>
                <GridItem w="100%" h="auto">
                  <b>HRDC Claim?&nbsp;</b>
                  {state.fullData.training_hrdc === 0 ? (
                    <BsXCircleFill color="red" />
                  ) : (
                    <BsCheckCircleFill color="green" />
                  )}
                </GridItem>
              </Grid>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <Tabs variant="unstyled">
        <Container maxW={"90%"} py="10px">
          <TabList>
            <Tab
              borderLeftRadius="10px"
              borderRightRadius="0px"
              p="10px"
              mr="10px"
              w="25%"
              fontSize="20px"
              color={themeCtx.mode ? "white" : "black"}
              bg={themeCtx.mode ? "#3b3b3b" : "#f4f8f1"}
              _selected={{
                bg: themeCtx.mode ? "#2b2b2b" : "#C1E4A0",
                borderBottom: "inherit",
              }}
              onClick={() => handleTab("Register")}
            >
              Register
            </Tab>
            <Tab
              borderRightRadius="0px"
              p="10px"
              mr="10px"
              w="25%"
              fontSize="20px"
              color={themeCtx.mode ? "white" : "black"}
              bg={themeCtx.mode ? "#3b3b3b" : "#f4f8f1"}
              _selected={{
                bg: themeCtx.mode ? "#2b2b2b" : "#C1E4A0",
                borderBottom: "inherit",
              }}
              onClick={() => handleTab("Join")}
            >
              Join
            </Tab>
            <Tab
              borderRadius="0px"
              p="10px"
              mr="10px"
              w="25%"
              fontSize="20px"
              color={themeCtx.mode ? "white" : "black"}
              bg={themeCtx.mode ? "#3b3b3b" : "#f4f8f1"}
              _selected={{
                bg: themeCtx.mode ? "#2b2b2b" : "#C1E4A0",
                borderBottom: "inherit",
              }}
              onClick={() => handleTab("Withdraw")}
            >
              Withdrawal
            </Tab>
            <Tab
              borderLeftRadius="0px"
              borderRightRadius="10px"
              p="10px"
              w="25%"
              fontSize="20px"
              color={themeCtx.mode ? "white" : "black"}
              bg={themeCtx.mode ? "#3b3b3b" : "#f4f8f1"}
              _selected={{
                bg: themeCtx.mode ? "#2b2b2b" : "#C1E4A0",
                borderBottom: "inherit",
              }}
              onClick={() => {
                traineeSummary();
                setTableLoader(true);
              }}
            >
              Summary
            </Tab>
          </TabList>
        </Container>
        <Container
          maxW={"88.5%"}
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          style={{ borderRadius: "10px" }}
        >
          <TabPanels>
            <TabPanel>
              <div style={{ position: "relative" }}>
                <Stack
                  direction={"row"}
                  position="absolute"
                  right="2%"
                  bottom="-80px"
                >
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        variant="outline"
                        colorScheme="green"
                        icon={<MdOutlineDocumentScanner />}
                      />
                    </PopoverTrigger>
                    <Portal
                      width="100px"
                      boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)"
                    >
                      <PopoverContent
                        bg={themeCtx.mode ? "#2b2b2b" : "white"}
                        width="200px"
                      >
                        <PopoverArrow />
                        <PopoverBody>
                          <VStack>
                            <Text
                              fontWeight="bold"
                              color={themeCtx.mode && "white"}
                            >
                              Generate
                            </Text>
                            <VStack>
                              {template &&
                                template.map((data) => (
                                  <Button
                                    onClick={() => {
                                      setSelectedTemplate(data);
                                      setGroupType(data.title);
                                      generateModal.onOpen();
                                    }}
                                  >
                                    {data.title.replaceAll("_", " ")}
                                  </Button>
                                ))}
                            </VStack>
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                  <Button
                    leftIcon={<AddIcon />}
                    bg="#33945f"
                    color="white"
                    variant="solid"
                    boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)"
                    width="200px"
                    _hover={{ bg: "green.600" }}
                    p="14px"
                    fontSize="14px"
                    onClick={addTrainee.onOpen}
                  >
                    Add Trainee
                  </Button>
                </Stack>
              </div>

              <StylesTrainee>
                <ReactTable
                  columns={columns}
                  data={data}
                  modulePage="trainee"
                  tableLoader={tableLoader}
                  themeCtx={themeCtx}
                />
              </StylesTrainee>
            </TabPanel>
            <TabPanel>
              <div
                style={{
                  position: "relative",
                }}
              >
                <Stack
                  direction="row"
                  style={{ position: "absolute", right: "2%", bottom: "-80px" }}
                >
                  <Tooltip
                    hasArrow
                    label="Create classroom"
                    bg="gray.300"
                    color="black"
                  >
                    <IconButton
                      variant="outline"
                      colorScheme="green"
                      icon={<SiGoogleclassroom />}
                      onClick={() => {
                        classroomModal.onOpen();
                      }}
                    />
                  </Tooltip>

                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        variant="outline"
                        colorScheme="green"
                        icon={<MdOutlineDocumentScanner />}
                      />
                    </PopoverTrigger>
                    <Portal
                      width="100px"
                      boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)"
                    >
                      <PopoverContent
                        bg={themeCtx.mode ? "#2b2b2b" : "white"}
                        width="200px"
                      >
                        <PopoverArrow />
                        <PopoverBody>
                          <VStack>
                            <Text
                              fontWeight="bold"
                              color={themeCtx.mode && "white"}
                            >
                              Generate
                            </Text>
                            <VStack>
                              {template &&
                                template.map((data) => (
                                  <Button
                                    onClick={() => {
                                      setSelectedTemplate(data);
                                      setGroupType(data.title);
                                      generateModal.onOpen();
                                    }}
                                  >
                                    {data.title.replaceAll("_", " ")}
                                  </Button>
                                ))}
                            </VStack>
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                  <Tooltip
                    hasArrow
                    label="Download trainee list excel"
                    bg="gray.300"
                    color="black"
                  >
                    <IconButton
                      variant="outline"
                      colorScheme="green"
                      aria-label="Download Excel"
                      icon={<AiOutlineFileExcel />}
                      onClick={() => {
                        let excelCol = [],
                          excelCol1 = [],
                          excelCol2 = [],
                          excelCol3 = [],
                          excelCol4 = [];
                        for (let i = 0; i < data.length; i++) {
                          excelCol.push([
                            i + 1,
                            data[i].trainee_name.toUpperCase(),
                            data[i].company.company_name.toUpperCase(),
                            data[i].trainee_position.toUpperCase(),
                            data[i].trainee_phone,
                            "",
                            data[i].trainee_email,
                            "",
                            "",
                            data[i].trainee_ic,
                            "",
                            "",
                            "",
                          ]);
                        }
                        for (let i = 0; i < data.length; i++) {
                          excelCol1.push([
                            i + 1,
                            data[i].trainee_name.toUpperCase(),
                            `${data[i].trainee_email} / ${data[i].trainee_phone}`,
                          ]);
                        }
                        for (let i = 0; i < data.length; i++) {
                          excelCol2.push([
                            i + 1,
                            data[i].trainee_name.toUpperCase(),
                          ]);
                        }
                        for (let i = 0; i < data.length; i++) {
                          excelCol3.push([
                            i + 1,
                            data[i].trainee_name.toUpperCase(),
                          ]);
                        }
                        for (let i = 0; i < data.length; i++) {
                          excelCol4.push([
                            i + 1,
                            data[i].trainee_name.toUpperCase(),
                            data[i].trainee_email,
                            "",
                            "",
                          ]);
                        }
                        // JSON.parse(data[i].trainee_address)[0].address +
                        //   ", " +
                        //   JSON.parse(data[i].trainee_address)[0].postcode +
                        //   ", " +
                        //   JSON.parse(data[i].trainee_address)[0].state,

                        // ADD DATA TO SHEET
                        const ws = XLSX.utils.json_to_sheet(excelCol);
                        const ws1 = XLSX.utils.json_to_sheet(excelCol1);
                        const ws2 = XLSX.utils.json_to_sheet(excelCol2);
                        const ws3 = XLSX.utils.json_to_sheet(excelCol3);
                        const ws4 = XLSX.utils.json_to_sheet(excelCol4);
                        const wb = XLSX.utils.book_new();

                        // RENAME HEADERS EVERY SHEET
                        XLSX.utils.sheet_add_aoa(
                          ws,
                          [
                            [
                              "No.",
                              "Name",
                              "Company",
                              "Position in Company",
                              "Phone",
                              "Google Drive",
                              "Email",
                              "Email Status",
                              "Password",
                              "IC",
                              "MyHalalGig Status",
                              "QuikHalal ID",
                              "QuikHalal Status",
                            ],
                          ],
                          { origin: "A1" }
                        );
                        XLSX.utils.sheet_add_aoa(
                          ws1,
                          [["No.", "Name", "Email / Phone Number"]],
                          { origin: "A1" }
                        );
                        XLSX.utils.sheet_add_aoa(ws2, [["No.", "Name"]], {
                          origin: "A1",
                        });
                        XLSX.utils.sheet_add_aoa(ws3, [["NO.", "Name"]], {
                          origin: "A1",
                        });
                        XLSX.utils.sheet_add_aoa(
                          ws4,
                          [
                            [
                              "NO",
                              "NAME",
                              "USERNAME: MYHALALGIG",
                              "USERNAME: QUIKHALAL",
                              "PASSWORD",
                            ],
                          ],
                          { origin: "A1" }
                        );

                        // APPEND SHEET
                        XLSX.utils.book_append_sheet(wb, ws, "List Name");
                        XLSX.utils.book_append_sheet(
                          wb,
                          ws1,
                          "Classroom Details"
                        );
                        XLSX.utils.book_append_sheet(
                          wb,
                          ws2,
                          "Assignment and Project"
                        );
                        XLSX.utils.book_append_sheet(
                          wb,
                          ws3,
                          "Print Exam Paper"
                        );
                        XLSX.utils.book_append_sheet(
                          wb,
                          ws4,
                          "QuikHalal & MyHalalGIG"
                        );
                        XLSX.writeFile(wb, `${state.name} Participants.xlsx`, {
                          compression: true,
                        });
                      }}
                    />
                  </Tooltip>

                  <Tooltip hasArrow label="Print" bg="gray.300" color="black">
                    <IconButton
                      variant="outline"
                      colorScheme="green"
                      aria-label="Print trainees"
                      icon={<FiPrinter />}
                      onClick={() => {
                        let focuser = setInterval(
                          () => window.dispatchEvent(new Event("focus")),
                          500
                        );
                        setPrintForm(true);
                        setTimeout(() => {
                          print({
                            printable: "printJS-form",
                            type: "html",
                            documentTitle: "Trainee List",
                            showModal: true,
                            onPrintDialogClose: function () {
                              clearInterval(focuser);
                              setPrintForm(false);
                            },
                            targetStyles: ["*"],
                            style:
                              ".table-training { } .table-head { width: 100%; } .table-list { word-wrap: break-word; } #hashtag { background-color: red !important;}",
                          });
                        }, 500);
                      }}
                    />
                  </Tooltip>
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        variant="outline"
                        colorScheme="green"
                        aria-label="Punch attendance"
                        icon={<FiClock />}
                      />
                    </PopoverTrigger>
                    <Portal boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)">
                      <PopoverContent bg={themeCtx.mode ? "#2b2b2b" : "white"}>
                        <PopoverArrow />
                        <PopoverBody my="40px">
                          <VStack>
                            <Text
                              fontWeight="bold"
                              color={themeCtx.mode && "white"}
                            >
                              Attendance
                            </Text>
                            <Center>
                              <HStack p="0px 40px 0px 40px">
                                <Button
                                  w="120px"
                                  bgColor="#4b85fa"
                                  boxShadow="xl"
                                  _hover={{ bgColor: "blue.300" }}
                                  onClick={() => {
                                    setPunch("in");
                                    qrCode(`${URL_PUNCHIN}${trainingID}`);
                                    modalPunchinOut.onOpen();
                                  }}
                                >
                                  Punch In
                                </Button>
                                <Button
                                  w="120px"
                                  bgColor="#f9c611"
                                  boxShadow="xl"
                                  _hover={{ bgColor: "yellow.300" }}
                                  onClick={() => {
                                    setPunch("out");
                                    qrCode(`${URL_PUNCHOUT}${trainingID}`);
                                    modalPunchinOut.onOpen();
                                  }}
                                >
                                  Punch Out
                                </Button>
                              </HStack>
                            </Center>
                            <Text color="red" textAlign={"center"}>
                              {state.date &&
                              new Date() >
                                new Date(
                                  state.date[0].startDate + " " + "08:00"
                                )
                                ? (state.date.length > 1
                                    ? new Date() >
                                      new Date(
                                        state.date[1].endDate + " " + "18:00"
                                      )
                                    : new Date() >
                                      new Date(
                                        state.date[0].endDate + " " + "18:00"
                                      )) &&
                                  "*The training has finished. Attendance punch was closed at 6:00pm, " +
                                    format(
                                      new Date(
                                        state.date.length > 1
                                          ? state.date[1].endDate
                                          : state.date[0].endDate
                                      ),
                                      "dd MMM y"
                                    ) +
                                    "*"
                                : "*The training is not started yet. Attendance punch will open at 8:00am, " +
                                  format(
                                    new Date(state.date[0].startDate),
                                    "dd MMM y."
                                  ) +
                                  "*"}
                            </Text>
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        variant="outline"
                        colorScheme="green"
                        icon={<FiLayers />}
                        aria-label="Grouping"
                      />
                    </PopoverTrigger>
                    <Portal boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)">
                      <PopoverContent bg={themeCtx.mode ? "#2b2b2b" : "white"}>
                        <PopoverArrow />
                        <PopoverBody>
                          <VStack>
                            <Text
                              fontWeight="bold"
                              color={themeCtx.mode && "white"}
                            >
                              Group
                            </Text>
                            <HStack>
                              <Button
                                bg={themeCtx.mode ? "#3b3b3b" : "#ebebeb"}
                                _hover={{ bg: themeCtx.mode && "#1b1b1b" }}
                                color={themeCtx.mode && "white"}
                                leftIcon={<FiFileText />}
                                onClick={() => {
                                  setGroupType("invoice");
                                  groupModal.onOpen();
                                }}
                              >
                                Invoice
                              </Button>
                              <Button
                                bg={themeCtx.mode ? "#3b3b3b" : "#ebebeb"}
                                _hover={{ bg: themeCtx.mode && "#1b1b1b" }}
                                color={themeCtx.mode && "white"}
                                leftIcon={<FiFile />}
                                onClick={() => {
                                  setGroupType("receipt");
                                  groupModal.onOpen();
                                }}
                              >
                                Receipt
                              </Button>
                            </HStack>
                            <Center>
                              <Text
                                as="i"
                                textAlign="center"
                                color="red"
                                fontSize="13px"
                              >
                                Reminder: Recipients for both group invoice and
                                receipt must be same.
                              </Text>
                            </Center>
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Stack>
              </div>
              <StylesTrainee>
                {printForm ? (
                  <ReactTable
                    columns={columnsPrint}
                    data={data}
                    tableLoader={tableLoader}
                    themeCtx={themeCtx}
                    modulePage="print"
                  />
                ) : (
                  <>
                    <ReactTable
                      columns={columns}
                      data={data}
                      modulePage="trainee"
                      tableLoader={tableLoader}
                      themeCtx={themeCtx}
                    />
                  </>
                )}
              </StylesTrainee>
            </TabPanel>
            <TabPanel>
              <StylesTrainee>
                <ReactTable
                  columns={columns}
                  data={data}
                  modulePage="trainee"
                  tableLoader={tableLoader}
                  themeCtx={themeCtx}
                />
              </StylesTrainee>
            </TabPanel>
            <TabPanel>
              <div style={{ position: "relative", marginBottom: "80px" }}>
                <Stack
                  direction="row"
                  w="100%"
                  mt="40px"
                  mb="20"
                  right="0"
                  pos="absolute"
                >
                  <Box w="60%">
                    <HStack>
                      <Button
                        fontSize="15px"
                        onClick={() => companySelect.onOpen()}
                      >
                        Filter&nbsp;
                        <FiFilter />
                      </Button>

                      {t3 && (
                        <Select
                          w="60%"
                          defaultValue=""
                          onChange={(e) => {
                            setDatePick(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {dateRange.map((date) => (
                            <option value={date}>
                              {format(new Date(date), "d/MM/yyyy")}
                            </option>
                          ))}
                        </Select>
                      )}
                      <Button
                        variant="outline"
                        ml="10px"
                        onClick={
                          t3
                            ? () => {
                                setT3(false);
                                setDatePick("");
                                setShowFooter(true);
                              }
                            : () => {
                                setT3(true);
                              }
                        }
                        color={t3 ? "red" : ""}
                      >
                        {t3 ? "Close T3 Form" : "Open T3 Form"}
                      </Button>
                      {t3 && (
                        <Button
                          variant="outline"
                          ml="10px"
                          fontSize="15px"
                          fontWeight="100"
                          onClick={() => {
                            showFooter
                              ? setShowFooter(false)
                              : setShowFooter(true);
                          }}
                        >
                          {showFooter ? "Remove Footer" : "Show Footer"}
                        </Button>
                      )}
                    </HStack>
                  </Box>

                  <Box w="40%" textAlign="right">
                    <span style={{ color: "red" }}>A</span>
                    <span style={{ color: themeCtx.mode && "white" }}>
                      {" "}
                      - Absent{" "}
                    </span>
                    <span style={{ color: "#D4AC0D", marginLeft: "5px" }}>
                      W
                    </span>
                    <span style={{ color: themeCtx.mode && "white" }}>
                      {" "}
                      - Withdrew
                    </span>

                    <Button
                      color={themeCtx.mode ? "#ffffff" : "#000000"}
                      variant="outline"
                      boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)"
                      width="200px"
                      p="14px"
                      ml="3"
                      fontSize="14px"
                      onClick={() => {
                        let focuser = setInterval(
                          () => window.dispatchEvent(new Event("focus")),
                          500
                        );
                        setPrintForm(true);
                        setTimeout(() => {
                          print({
                            printable: !t3 ? "printSummary" : "printT3",
                            type: "html",
                            documentTitle: state.name + " Attendance",
                            showModal: true,
                            maxWidth: 1123,
                            onPrintDialogClose: function () {
                              clearInterval(focuser);
                              setPrintForm(false);
                            },
                            targetStyles: ["*"],
                            style:
                              ".footer2{ margin-left:350px; } .footer-container{ margin-top:20px; } #printSummary{ font-family:Arial,sans-serif; border:1px solid;} #header img{text-align:center;} td:nth-child(2){text-align:left; width:60px;} td{padding:5px;text-align:center;}",
                          });
                        }, 500);
                      }}
                    >
                      <FiPrinter />
                      &nbsp; Print
                    </Button>
                  </Box>
                </Stack>
                {summaryComp.length > 0 && (
                  <HStack>
                    <Text>
                      Filtered by {summaryComp.length} company/companies.
                    </Text>
                    <Button
                      variant="outline"
                      ml="10px"
                      onClick={() => traineeSummary()}
                      color="red"
                      fontSize="13px"
                      h="fit"
                    >
                      Clear filter
                    </Button>
                  </HStack>
                )}
              </div>
              {!t3 ? (
                <SummTable
                  date={dateRange}
                  data={summary}
                  setData={setSummary}
                  course={state.course_id}
                  training={state.name}
                  tableLoader={tableLoader}
                  print={printForm}
                />
              ) : (
                datepick && (
                  <T3Form
                    date={datepick}
                    data={summary}
                    footer={showFooter}
                    trainingmode={state.fullData.training_mode}
                  />
                )
              )}
            </TabPanel>
          </TabPanels>
        </Container>
      </Tabs>

      {/* -----------------------------------start add drawer-------------------------- */}
      <Drawer
        isOpen={addTrainee.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          setExcel(false);
          addTrainee.onClose();
        }}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent
          overflowY={"auto"}
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              ADD TRAINEE
            </DrawerHeader>

            <DrawerBody>
              <Center>
                <VStack>
                  <b>UPLOAD EXCEL FILE</b>
                  <Excel handleBigData={setBigData} handleExcel={setExcel} />
                </VStack>
              </Center>

              {excel === false ? (
                <div>
                  <Text align="center" fontWeight="bold" mt="10px">
                    OR
                  </Text>
                  <FormControl isInvalid={err.name}>
                    <FormLabel mt="10px" fontWeight="bold">
                      NAME <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="name"
                      placeholder="Name"
                      {...register("traineeName")}
                      onBlur={clearErr}
                    ></Input>
                  </FormControl>
                  {err.name && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.company}>
                    <FormLabel mt="10px" fontWeight="bold">
                      COMPANY <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <ChakraSelectReact
                      id="company"
                      fontSize={{ md: "14px", base: "12px" }}
                      options={companies}
                      placeholder="Select company"
                      onChange={handleCompany}
                      closeMenuOnSelect={true}
                    />
                  </FormControl>
                  {err.company && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl
                    isInvalid={err.position}
                    isDisabled={!allowAddress}
                  >
                    <FormLabel mt="10px" fontWeight="bold">
                      POSITION <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="position"
                      placeholder="Position"
                      {...register("traineePosition")}
                      onBlur={clearErr}
                    ></Input>
                  </FormControl>
                  {err.position && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.ic}>
                    <FormLabel mt="10px" fontWeight="bold">
                      IC NUMBER / PASSPORT NO
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="ic"
                      type="text"
                      placeholder="IC/PASSPORT No"
                      {...register("traineeIC")}
                      onBlur={clearErr}
                    ></Input>
                  </FormControl>
                  <FormControl isInvalid={err.phone}>
                    <FormLabel mt="10px" fontWeight="bold">
                      PHONE NUMBER <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <HStack>
                      <Select
                        w="30%"
                        defaultValue="+60"
                        {...register("traineeCodePhone")}
                      >
                        {countries
                          .sort((a, b) => {
                            if (a.label.toLowerCase() < b.label.toLowerCase())
                              return -1;
                            if (a.label.toLowerCase() > b.label.toLowerCase())
                              return 1;
                            return 0;
                          })
                          .map((item) => (
                            <option
                              style={{ width: "100px" }}
                              value={item.code}
                            >
                              {item.value} {item.code}
                            </option>
                          ))}
                      </Select>
                      <Input
                        w="70%"
                        id="phone"
                        type="number"
                        placeholder="Phone number"
                        {...register("traineePhone")}
                        onBlur={clearErr}
                      ></Input>
                    </HStack>
                  </FormControl>
                  <FormControl isInvalid={err.email}>
                    <FormLabel mt="10px" fontWeight="bold">
                      EMAIL <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="email"
                      type="email"
                      placeholder="Email"
                      {...register("traineeEmail")}
                      onBlur={clearErr}
                    ></Input>
                  </FormControl>
                  {err.email && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.address}>
                    <FormLabel mt="10px" fontWeight="bold">
                      ADDRESS <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Textarea
                      id="address"
                      resize="none"
                      style={{ height: "120px" }}
                      placeholder="Address"
                      defaultValue={
                        allowAddress ? companyAddress[0].address : ""
                      }
                      isDisabled={allowAddress}
                      {...register("traineeAddress")}
                      onBlur={clearErr}
                    ></Textarea>
                  </FormControl>
                  {err.address && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <HStack>
                    <Box w="30%">
                      <FormControl isInvalid={err.postcode}>
                        <FormLabel mt="10px" fontWeight="bold">
                          POSTCODE <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          id="postcode"
                          placeholder="Postcode"
                          defaultValue={
                            allowAddress ? companyAddress[0].postcode : ""
                          }
                          isDisabled={allowAddress}
                          {...register("traineePostcode")}
                          onBlur={handleTraineeAddress}
                        />
                        <FormHelperText color="transparent">
                          Type in the state if it doesn't exist in the list.
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <Box w="30%">
                      <FormControl>
                        <FormLabel mt="10px" fontWeight="bold">
                          CITY <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          id="city"
                          placeholder="City"
                          defaultValue={
                            allowAddress ? companyAddress[0].city : ""
                          }
                          isDisabled={
                            companyAddress &&
                            companyAddress[0].city &&
                            allowAddress
                          }
                          {...register("traineeCity")}
                          onBlur={clearErr}
                        />
                        <FormHelperText color="transparent">
                          Type in the state if it doesn't exist in the list.
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <Box w="40%">
                      <FormControl isInvalid={err.state}>
                        <FormLabel mt="10px" fontWeight="bold">
                          STATE <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          list="MyStates"
                          placeholder="State"
                          defaultValue={
                            allowAddress ? companyAddress[0].state : ""
                          }
                          {...allowAddress}
                          isDisabled={allowAddress}
                          {...register("traineeState")}
                          onBlur={clearErr}
                        />
                        <datalist id="MyStates">
                          {MyStates.map((option) => (
                            <option key={option} value={option} />
                          ))}
                        </datalist>
                        <FormHelperText>
                          Type in the state if it doesn't exist in the list.
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </HStack>
                  <FormControl isInvalid={err.state}>
                    <FormLabel mt="10px" fontWeight="bold">
                      COUNTRY <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      id="country"
                      defaultValue="Malaysia"
                      isDisabled={
                        companyAddress &&
                        companyAddress[0].country &&
                        allowAddress
                      }
                      {...register("traineeCountry")}
                      onBlur={clearErr}
                    >
                      {countries.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel mt="10px" fontWeight="bold">
                      HRDC Claimed{" "}
                    </FormLabel>
                    <Checkbox value="1" {...register("traineeHRDF")}>
                      Yes
                    </Checkbox>
                  </FormControl>
                  <FormControl>
                    <FormLabel mt="10px" fontWeight="bold">
                      SPECIAL DIETARY / ALLERGIES
                    </FormLabel>
                    <Input
                      placeholder="Fill if applicable"
                      {...register("traineeAllergies")}
                    ></Input>
                  </FormControl>
                  <HStack>
                    <FormControl>
                      <FormLabel mt="10px" fontWeight="bold">
                        REFERRER
                      </FormLabel>
                      <Input
                        placeholder="Referrer"
                        {...register("traineeReferrer")}
                      ></Input>
                    </FormControl>
                    <FormControl>
                      <FormLabel mt="10px" fontWeight="bold">
                        PROMO CODE
                      </FormLabel>
                      <Select
                        w="100%"
                        {...register("discID")}
                        bg={themeCtx.mode ? "#2b2b2b" : "white"}
                      >
                        <option
                          value=""
                          style={{
                            backgroundColor: themeCtx.mode && "#2b2b2b",
                          }}
                        >
                          Select promo code
                        </option>

                        {promoCode.map((elem) => (
                          <option
                            value={elem.value}
                            style={{
                              backgroundColor: themeCtx.mode && "#2b2b2b",
                            }}
                          >
                            {elem.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </HStack>
                </div>
              ) : (
                ""
              )}
            </DrawerBody>

            <Center>
              <Stack direction="column">
                {excel && (
                  <ChakraSelectReact
                    id="company"
                    fontSize={{ md: "14px", base: "12px" }}
                    options={companies}
                    placeholder="Select company"
                    onChange={handleCompany}
                    closeMenuOnSelect={true}
                  />
                )}

                <DrawerFooter>
                  <Button
                    fontWeight="medium"
                    w="200px"
                    color="white"
                    bg="#33945f"
                    _hover={{ bg: "green.700" }}
                    type="submit"
                  >
                    Add
                  </Button>
                  <Button
                    fontWeight="medium"
                    w="200px"
                    variant="outline"
                    ml={3}
                    onClick={() => {
                      reset();
                      resetErr();
                      setExcel(false);
                      addTrainee.onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </DrawerFooter>
              </Stack>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* -----------------------------------end add drawer-------------------------- */}
      {/* -----------------------------------start edit drawer-------------------------- */}
      <Drawer
        isOpen={editTrainee.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          setChangeCompany(false);
          setAllowAddress(false);
          setNewCompany("");
          editTrainee.onClose();
        }}
        size="lg"
        fontSize="14px"
      >
        <DrawerOverlay />
        <DrawerContent
          overflowY={"auto"}
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              EDIT TRAINEE
            </DrawerHeader>

            <DrawerBody>
              <FormControl isInvalid={err.name} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINEE NAME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Trainee name"
                  defaultValue={editData.trainee_name}
                  {...register("traineeName")}
                ></Input>
              </FormControl>
              <FormControl isInvalid={err.company}>
                <FormLabel mt="10px" fontWeight="bold">
                  COMPANY <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box
                  borderRadius="10px"
                  borderWidth="2px"
                  mb="7px"
                  pl="15px"
                  py="7px"
                  fontSize="17px"
                >
                  {editData.company &&
                    (changeCompany
                      ? newCompany
                      : editData.company.company_name)}
                </Box>
                {changeCompany === true ? (
                  <ChakraSelectReact
                    disabled
                    fontSize={{ md: "14px", base: "12px" }}
                    options={companies}
                    placeholder="Select company"
                    onChange={editCompany}
                    closeMenuOnSelect={true}
                  />
                ) : (
                  <Link
                    color="blue"
                    fontSize="15px"
                    onClick={() => setChangeCompany(true)}
                  >
                    Click here to change company
                  </Link>
                )}
              </FormControl>
              <FormControl isInvalid={err.ic}>
                <FormLabel mt="10px" fontWeight="bold">
                  IC NUMBER / PASSPORT NO
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  type="text"
                  placeholder="IC/PASSPORT NO"
                  defaultValue={editData.trainee_ic}
                  {...register("traineeIC")}
                ></Input>
              </FormControl>
              <FormControl isInvalid={err.phone}>
                <FormLabel mt="10px" fontWeight="bold">
                  TRAINEE PHONE NUMBER <span style={{ color: "red" }}>*</span>
                </FormLabel>
                {changePhone ? (
                  <>
                    <HStack>
                      <Select
                        w="30%"
                        defaultValue="+60"
                        {...register("traineeCodePhone")}
                      >
                        {countries
                          .sort((a, b) => {
                            if (a.label.toLowerCase() < b.label.toLowerCase())
                              return -1;
                            if (a.label.toLowerCase() > b.label.toLowerCase())
                              return 1;
                            return 0;
                          })
                          .map((item) => (
                            <option
                              style={{ width: "100px" }}
                              value={item.code}
                            >
                              {item.value} {item.code}
                            </option>
                          ))}
                      </Select>
                      <Input
                        w="70%"
                        id="phone"
                        type="number"
                        placeholder="Phone number"
                        {...register("traineePhone")}
                        onBlur={clearErr}
                      ></Input>
                    </HStack>
                    <Link
                      color="red"
                      fontSize="15px"
                      onClick={() => setChangePhone(false)}
                    >
                      Cancel
                    </Link>
                  </>
                ) : (
                  <>
                    <Box
                      borderRadius="10px"
                      borderWidth="2px"
                      pl="15px"
                      py="7px"
                      fontSize="17px"
                    >
                      {editData && editData.trainee_phone}
                    </Box>
                    <Link
                      color="blue"
                      fontSize="15px"
                      onClick={() => setChangePhone(true)}
                    >
                      Click here to change phone number
                    </Link>
                  </>
                )}
              </FormControl>
              <FormControl isInvalid={err.position}>
                <FormLabel mt="10px" fontWeight="bold">
                  POSITION <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Position"
                  defaultValue={editData.trainee_position}
                  {...register("traineePosition")}
                ></Input>
              </FormControl>
              <FormControl isInvalid={err.email}>
                <FormLabel mt="10px" fontWeight="bold">
                  EMAIL <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  type="email"
                  placeholder="Email"
                  defaultValue={editData.trainee_email}
                  {...register("traineeEmail")}
                ></Input>
              </FormControl>
              <FormControl isInvalid={err.address}>
                <FormLabel mt="10px" fontWeight="bold">
                  ADDRESS <span style={{ color: "red" }}>*</span>
                </FormLabel>
                {changeCompany ? (
                  <Textarea
                    resize="none"
                    style={{ height: "120px" }}
                    placeholder="Address"
                    defaultValue={allowAddress ? companyAddress[0].address : ""}
                    isDisabled={allowAddress}
                    {...register("traineeAddress")}
                  ></Textarea>
                ) : editData.company_id > 1 ? (
                  <Box
                    _hover={{ cursor: "not-allowed" }}
                    borderRadius="10px"
                    borderWidth="2px"
                    mb="7px"
                    pl="15px"
                    py="7px"
                    fontSize="17px"
                    h="120px"
                  >
                    {showAddress[0].address}
                  </Box>
                ) : (
                  <Textarea
                    resize="none"
                    style={{ height: "120px" }}
                    placeholder="Address"
                    defaultValue={showAddress[0].address}
                    isDisabled={editData.company_id > 1 ? true : false}
                    {...register("traineeAddress")}
                  ></Textarea>
                )}
              </FormControl>

              <HStack>
                <Box w="30%">
                  <FormControl isInvalid={err.postcode}>
                    <FormLabel fontWeight="bold">
                      POSTCODE <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    {changeCompany ? (
                      <Input
                        mt="5px"
                        resize="none"
                        style={{ height: "50px" }}
                        placeholder="Postcode"
                        defaultValue={
                          allowAddress ? companyAddress[0].postcode : ""
                        }
                        isDisabled={allowAddress}
                        {...register("traineePostcode")}
                      ></Input>
                    ) : editData.company_id > 1 ? (
                      <Box
                        _hover={{ cursor: "not-allowed" }}
                        borderRadius="10px"
                        borderWidth="2px"
                        mb="7px"
                        pl="15px"
                        py="7px"
                        fontSize="17px"
                      >
                        {showAddress[0].postcode}
                      </Box>
                    ) : (
                      <Input
                        id="postcode"
                        mt="5px"
                        resize="none"
                        style={{ height: "50px" }}
                        placeholder="Postcode"
                        defaultValue={showAddress[0].postcode}
                        isDisabled={editData.company_id > 1 ? true : false}
                        {...register("traineePostcode")}
                        onBlur={handleTraineeAddress}
                      ></Input>
                    )}
                    <FormHelperText color="transparent">
                      Type in the
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box w="30%">
                  <FormControl isInvalid={err.city}>
                    <FormLabel fontWeight="bold">
                      CITY <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    {changeCompany ? (
                      <Input
                        mt="5px"
                        resize="none"
                        style={{ height: "50px" }}
                        placeholder="City"
                        defaultValue={
                          allowAddress ? companyAddress[0].city : ""
                        }
                        isDisabled={
                          companyAddress &&
                          companyAddress[0].city &&
                          allowAddress
                        }
                        {...register("traineeCity")}
                      ></Input>
                    ) : editData.company_id > 1 ? (
                      <Box
                        h="45px"
                        _hover={{ cursor: "not-allowed" }}
                        borderRadius="10px"
                        borderWidth="2px"
                        mb="7px"
                        pl="15px"
                        py="7px"
                        fontSize="17px"
                      >
                        {showAddress[0].city}
                      </Box>
                    ) : (
                      <Input
                        id="city"
                        mt="5px"
                        resize="none"
                        style={{ height: "50px" }}
                        placeholder="City"
                        defaultValue={showAddress[0].city}
                        isDisabled={editData.company_id > 1 ? true : false}
                        {...register("traineeCity")}
                        onBlur={handleTraineeAddress}
                      ></Input>
                    )}
                    <FormHelperText color="transparent">
                      Type in the
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box w="60%">
                  <FormControl isInvalid={err.state}>
                    <FormLabel fontWeight="bold">
                      STATE <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    {changeCompany ? (
                      <Input
                        mt="5px"
                        resize="none"
                        style={{ height: "50px" }}
                        placeholder="State"
                        defaultValue={
                          allowAddress ? companyAddress[0].state : ""
                        }
                        isDisabled={allowAddress}
                        {...register("traineeState")}
                      ></Input>
                    ) : editData.company_id > 1 ? (
                      <Box
                        h="45px"
                        _hover={{ cursor: "not-allowed" }}
                        borderRadius="10px"
                        borderWidth="2px"
                        mb="7px"
                        pl="15px"
                        py="7px"
                        fontSize="17px"
                      >
                        {showAddress[0].state}
                      </Box>
                    ) : (
                      <>
                        <Input
                          list="MyStates"
                          mt="5px"
                          resize="none"
                          style={{ height: "50px" }}
                          placeholder="State"
                          defaultValue={showAddress[0].state}
                          isDisabled={editData.company_id > 1 ? true : false}
                          {...register("traineeState")}
                        />
                        <datalist id="MyStates">
                          {MyStates.map((option) => (
                            <option key={option} value={option} />
                          ))}
                        </datalist>
                      </>
                    )}
                    <FormHelperText>
                      Type in the state if it doesn't exist in the list.
                    </FormHelperText>
                  </FormControl>
                </Box>
              </HStack>
              <FormControl isInvalid={err.country}>
                <FormLabel fontWeight="bold">
                  COUNTRY <span style={{ color: "red" }}>*</span>
                </FormLabel>
                {changeCompany ? (
                  <Select
                    id="country"
                    defaultValue="Malaysia"
                    isDisabled={
                      companyAddress &&
                      companyAddress[0].country &&
                      allowAddress
                    }
                    {...register("traineeCountry")}
                    onBlur={clearErr}
                  >
                    {countries.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </Select>
                ) : editData.company_id > 1 ? (
                  <Box
                    h="45px"
                    _hover={{ cursor: "not-allowed" }}
                    borderRadius="10px"
                    borderWidth="2px"
                    mb="7px"
                    pl="15px"
                    py="7px"
                    fontSize="17px"
                  >
                    {showAddress[0].country}
                  </Box>
                ) : (
                  <Select
                    id="country"
                    defaultValue={showAddress[0].country}
                    isDisabled={
                      companyAddress &&
                      companyAddress[0].country &&
                      allowAddress
                    }
                    {...register("traineeCountry")}
                    onBlur={clearErr}
                  >
                    {countries.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </Select>
                )}
              </FormControl>
              <HStack>
                {/* <Box w="50%">
                  <FormControl>
                    <FormLabel mt="10px" fontWeight="bold">
                      PAYMENT STATUS
                    </FormLabel>
                    <Select
                      w="100%"
                      bg={themeCtx.mode ? "#2b2b2b" : "white"}
                      defaultValue={editData.trainee_payment}
                      {...register("traineePayment")}
                    >
                      <option
                        value=""
                        style={{
                          backgroundColor: themeCtx.mode ? "#2b2b2b" : "white",
                        }}
                        disabled
                      >
                        Select payment status
                      </option>
                      <option
                        value={1}
                        style={{
                          backgroundColor: themeCtx.mode ? "#2b2b2b" : "white",
                        }}
                      >
                        Pending
                      </option>{" "}
                      <option
                        value={2}
                        style={{
                          backgroundColor: themeCtx.mode ? "#2b2b2b" : "white",
                        }}
                      >
                        Processing
                      </option>{" "}
                      <option
                        value={3}
                        style={{
                          backgroundColor: themeCtx.mode ? "#2b2b2b" : "white",
                        }}
                      >
                        Completed
                      </option>
                    </Select>
                  </FormControl>
                </Box> */}
                <Box w="50%">
                  <FormControl>
                    <FormLabel mt="10px" fontWeight="bold">
                      HRDC Claimed
                    </FormLabel>
                    <CheckboxGroup
                      colorScheme="green"
                      defaultValue={[editData.trainee_hrdf]}
                    >
                      <Checkbox value="1" {...register("traineeHRDF")}>
                        Yes
                      </Checkbox>
                    </CheckboxGroup>
                  </FormControl>
                </Box>
              </HStack>

              <FormControl>
                <FormLabel mt="10px" fontWeight="bold">
                  SPECIAL DIETARY / ALLERGIES
                </FormLabel>
                <Input
                  placeholder="Fill if applicable"
                  defaultValue={editData.trainee_allergies}
                  {...register("traineeAllergies")}
                ></Input>
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel mt="10px" fontWeight="bold">
                    REFERRER
                  </FormLabel>
                  <Input
                    placeholder="Referrer"
                    defaultValue={editData.trainee_referrer}
                    {...register("traineeReferrer")}
                  ></Input>
                </FormControl>
                <FormControl>
                  <FormLabel mt="10px" fontWeight="bold">
                    PROMO CODE
                  </FormLabel>
                  {/* <ChakraSelectReact
                    fontSize={{ md: "14px", base: "12px" }}
                    options={promoCode}
                    placeholder="Select promo code"
                    closeMenuOnSelect={true}
                  /> */}
                  {/* <Select
                    placeholder="Select promo code"
                    defaultValue={editData.trainee_promo}
                    {...register("traineePromo")}
                  >
                    {promoCode.map((elem) => (
                      <option value={elem.discount_id}>
                        {elem.discount_name}
                      </option>
                    ))}
                  </Select> */}
                  <Select
                    disabled={
                      showAddress[0].country === "Malaysia" ? false : true
                    }
                    w="100%"
                    backgroundColor={themeCtx.mode ? "#2b2b2b" : "white"}
                    defaultValue={
                      showAddress[0].country === "Malaysia"
                        ? editData.trainee_discount
                        : ""
                    }
                    {...register("discID")}
                  >
                    <option
                      value=""
                      style={{
                        backgroundColor: themeCtx.mode ? "#2b2b2b" : "white",
                      }}
                    >
                      Select promo code
                    </option>
                    {promoCode.map((elem) => (
                      <option
                        disabled={elem.usage === elem.quota ? true : false}
                        value={elem.value}
                        style={{
                          backgroundColor: themeCtx.mode ? "#2b2b2b" : "white",
                        }}
                      >
                        {elem.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HStack>
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    resetErr();
                    setChangeCompany(false);
                    setAllowAddress(false);
                    setNewCompany("");
                    editTrainee.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* -----------------------------------end edit drawer-------------------------- */}
      {/* -----------------------------------------punch in modal------------------------------------------- */}
      <Modal
        isOpen={modalPunchinOut.isOpen}
        onClose={modalPunchinOut.onClose}
        size="3xl"
        display="grid"
      >
        <ModalOverlay />
        <ModalContent height="75%" alignItems="center">
          <ModalHeader textAlign="center" fontSize="25px" py="5">
            Copy the QR Code to punch {punch === "in" ? "in" : "out"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div
              ref={downloadRef}
              style={{ display: "flex", maxWidth: "400px", maxHeight: "400px" }}
            >
              <img src={qr} />
            </div>
          </ModalBody>

          <ModalFooter>
            {qr && (
              <>
                <Button
                  mr={3}
                  bgColor="#33945F"
                  color="white"
                  onClick={handleDownloadImage}
                >
                  Download
                </Button>
              </>
            )}
            <Button variant="outline" onClick={modalPunchinOut.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* -----------------------------------------punch in modal end------------------------------------------- */}
      {/* -----------------------------------------hrdf modal--------------------------------------------------- */}
      <Modal isOpen={mailHRDF.isOpen} onClose={mailHRDF.onClose} size="6xl">
        <ModalOverlay />
        <ModalContent
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          color={themeCtx.mode && "white"}
        >
          <ModalBody>
            <Stack spacing={4} direction="row">
              <Box width={"60%"} height={"100%"}>
                <FormControl mt={4}>
                  <FormLabel>From</FormLabel>
                  <InputGroup>
                    <Input
                      value="info1@holisticslab.my"
                      disabled={!allowEdit}
                    />
                    <InputRightElement>
                      <IconButton
                        colorScheme="gray"
                        fontSize="20px"
                        icon={<EditIcon color="black" />}
                        onClick={() => setAllowEdit(!allowEdit)}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>To</FormLabel>
                  <Input />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Subject</FormLabel>
                  <Input />
                </FormControl>
                <FormControl mt={4}>
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                  ></ReactQuill>
                </FormControl>
              </Box>
              <Box w="40%">
                <VStack>
                  <AspectRatio width="100%" h="348px">
                    <Box
                      my="20px"
                      borderColor="gray.300"
                      borderStyle="dashed"
                      borderWidth="2px"
                      rounded="md"
                      shadow="sm"
                      role="group"
                      _hover={{
                        shadow: "md",
                      }}
                      whileHover="hover"
                    >
                      <Box position="relative" height="100%" width="100%">
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          height="100%"
                          width="100%"
                          display="flex"
                          flexDirection="column"
                        >
                          <Stack
                            height="100%"
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justify="center"
                            spacing="4"
                          >
                            <Stack p="8" textAlign="center" spacing="1">
                              <Heading
                                fontSize="lg"
                                color="gray.700"
                                fontWeight="bold"
                              >
                                Click to upload
                              </Heading>
                              <Text fontWeight="light">or drop images</Text>
                            </Stack>
                            <Box>
                              <FiUpload color="#9fa2b4" size="40px" />
                            </Box>
                          </Stack>
                        </Box>
                        <Input
                          type="file"
                          height="100%"
                          width="100%"
                          position="absolute"
                          top="0"
                          left="0"
                          opacity="0"
                          aria-hidden="true"
                          accept="image/*"
                        />
                      </Box>
                    </Box>
                  </AspectRatio>
                  <br />
                  <FormControl display="flex" alignItems="center">
                    <FormLabel w="50%">Return receipt</FormLabel>
                    <Switch id="email-alerts" />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel w="50%">Delivery status notification</FormLabel>
                    <Switch id="email-alerts" />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel w="50%">Priority</FormLabel>
                    <Select w="50%" size="sm" placeholder="Select">
                      <option>Low</option>
                      <option>Normal</option>
                      <option>High</option>
                    </Select>
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel w="50%">Save sent messages in </FormLabel>
                    <Select w="50%" size="sm" placeholder="Select">
                      <option>Drafts</option>
                      <option>Sent</option>
                      <option>Trash</option>
                      <option>Inbox</option>
                    </Select>
                  </FormControl>
                </VStack>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                bg="#33945f"
                color="white"
                mr={3}
                fontSize={"14px"}
                w={"10%"}
              >
                Send
              </Button>
              <Button
                variant="outline"
                onClick={mailHRDF.onClose}
                fontSize={"14px"}
                w={"10%"}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* --------------------------------hrdf modal end------------------------------------------------------ */}
      {/* --------------------------------groupModal modal start------------------------------------------------------ */}
      <Modal
        size="4xl"
        isOpen={groupModal.isOpen}
        onClose={() => {
          setGroupType("");
          reset();
          groupModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent
          bgColor={themeCtx.mode && "#2b2b2b"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(groupSubmit)}>
            <>
              <ModalHeader
                bg={themeCtx.mode ? "#1b1b1b" : "#f1f1f1"}
                borderTopRadius={8}
                alignContent="center"
              >
                {groupType === "invoice" ? "Group Invoice" : "Group Receipt"}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody p="30px">
                {groupType === "invoice" ? (
                  <Box p="5px">
                    <b>Invoice Number: </b>
                    <Input
                      mb="10px"
                      placeholder="Insert invoice num"
                      {...register("groupInvNo")}
                    ></Input>
                    <b>Invoice Date: </b>
                    <Input
                      mb="10px"
                      type="date"
                      {...register("groupInvDate")}
                    />
                  </Box>
                ) : (
                  <Box p="5px">
                    <b>Receipt Number: </b>
                    <Input
                      mb="10px"
                      placeholder="Insert receipt num"
                      {...register("groupReceiptNo")}
                    />
                    <b>Receipt Date: </b>
                    <Input
                      mb="10px"
                      type="date"
                      {...register("groupReceiptDate")}
                    />
                    <b>Receipt Amount: </b>
                    <Input
                      mb="10px"
                      placeholder="Insert receipt amount"
                      {...register("groupReceiptAmount")}
                    />
                  </Box>
                )}

                <div style={{ position: "absolute", right: "20px" }}>
                  <Box
                    borderRadius="5px"
                    px="10px"
                    bg={themeCtx.mode ? "#3b3b3b" : "#f1f1f1"}
                  >
                    <span style={{ color: "green" }}>☑</span> HRDC Claim{" "}
                    <span style={{ color: "red" }}>☒</span> Non-claimable
                  </Box>
                </div>
                <br />
                {data &&
                  data.map(
                    (data) =>
                      data.company_id !== 1 &&
                      data.trainee_hrdf === 0 && (
                        <Stack>
                          <Checkbox
                            style={{ textTransform: "capitalize" }}
                            {...register("groupModalCheckbox")}
                            value={data.trainee_id}
                          >
                            {data.trainee_hrdf === 1 ? (
                              <span style={{ color: "green" }}>☑</span>
                            ) : (
                              <span style={{ color: "red" }}>☒</span>
                            )}{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {data.company.company_name}
                            </span>
                            {": "}
                            {data.trainee_name.toLowerCase()}
                          </Checkbox>
                        </Stack>
                      )
                  )}
              </ModalBody>

              <ModalFooter>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    type="submit"
                    bg="#33945f"
                    color="white"
                    onClick={() => {
                      setButtonType("view");
                    }}
                    // variant="ghost"
                    mr={3}
                  >
                    View {groupType === "invoice" ? "Invoice" : "Receipt"}
                  </Button>
                  &nbsp;
                  <Button variant="outline" onClick={groupModal.onClose}>
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </>
          </form>
        </ModalContent>
      </Modal>
      {/* --------------------------------groupModal modal end------------------------------------------------------ */}
      {/* --------------------------------view modal start------------------------------------------------------ */}
      <Modal size="4xl" isOpen={viewModal.isOpen} onClose={viewModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="#f1f1f1" borderTopRadius={10} alignContent="center">
            {invoiceDetail.isOpen || groupType === "invoice"
              ? "View Invoice"
              : "View Receipt"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: viewData,
              }}
            />
          </ModalBody>

          <ModalFooter>
            <>
              <Button
                variant="ghost"
                mr={3}
                onClick={async () => {
                  if (groupModal.isOpen) {
                    if (groupType === "invoice") {
                      await CRUDFunction.download(
                        `${URL_STAGING}invoice/downloadGroup`,
                        groupModalData
                      );
                    } else if (groupType === "receipt") {
                      await CRUDFunction.download(
                        `${URL_STAGING}receipt/downloadGroup`,
                        groupModalData
                      );
                    }
                  } else {
                    if (invoiceDetail.isOpen) {
                      await CRUDFunction.download(
                        `${URL_STAGING}invoice/download`,
                        modalData
                      );
                    } else if (receiptDetail.isOpen) {
                      await CRUDFunction.download(
                        `${URL_STAGING}receipt/download`,
                        modalData
                      );
                    }
                  }
                }}
              >
                Download
              </Button>
              <Button
                colorScheme="green"
                mr={3}
                onClick={async () => {
                  loading.onOpen();
                  if (groupModal.isOpen) {
                    loading.onOpen();
                    let res3;
                    if (groupType === "invoice") {
                      res3 = await CRUDFunction.update(
                        `${URL_STAGING}invoice/createGroup`,
                        groupModalData
                      );

                      if (res3.message !== "Mail has sent.") {
                        loading.onClose();
                        reset();
                        setButtonType("");
                        viewModal.onClose();
                        groupModal.onClose();
                        setInvNum();
                        setLoader(false);
                        setAlert({
                          ...alert,
                          class: "show",
                          status: "error",
                          message: "Please try again.",
                        });
                        timer();
                      } else {
                        loading.onClose();
                        reset();
                        setButtonType("");
                        viewModal.onClose();
                        groupModal.onClose();
                        setInvNum();
                        setLoader(false);
                        setAlert({
                          ...alert,
                          class: "show",
                          status: "success",
                          message: "Invoice is successfully sent to email.",
                        });
                        timer();
                      }
                    } else if (groupType === "receipt") {
                      res3 = await CRUDFunction.update(
                        `${URL_STAGING}receipt/createGroup`,
                        groupModalData
                      );
                      if (res3.message !== "Mail has sent.") {
                        loading.onClose();
                        reset();
                        setButtonType("");
                        viewModal.onClose();
                        groupModal.onClose();
                        setInvNum();
                        setLoader(false);
                        setAlert({
                          ...alert,
                          class: "show",
                          status: "error",
                          message: "Please try again.",
                        });
                        timer();
                      } else {
                        loading.onClose();
                        reset();
                        setButtonType("");
                        viewModal.onClose();
                        groupModal.onClose();
                        setReceiptDisplay({
                          receiptNum: "",
                          receiptAmount: "",
                        });
                        setLoader(false);
                        setAlert({
                          ...alert,
                          class: "show",
                          status: "success",
                          message: "Receipt is successfully sent to email.",
                        });
                        timer();
                      }
                    }
                  } else {
                    loading.onOpen();
                    let res;
                    if (invoiceDetail.isOpen) {
                      res = await CRUDFunction.update(
                        `${URL_STAGING}invoice/create`,
                        modalData
                      );

                      if (res.message !== "Mail has sent.") {
                        loading.onClose();
                        reset();
                        setButtonType("");
                        viewModal.onClose();
                        invoiceDetail.onClose();
                        setInvNum();
                        setLoader(false);
                        setAlert({
                          ...alert,
                          class: "show",
                          status: "error",
                          message: "Please try again.",
                        });
                        timer();
                      } else {
                        loading.onClose();
                        reset();
                        setButtonType("");
                        viewModal.onClose();
                        invoiceDetail.onClose();
                        setInvNum();
                        setLoader(false);
                        setAlert({
                          ...alert,
                          class: "show",
                          status: "success",
                          message: "Invoice is successfully sent to email.",
                        });
                        timer();
                      }
                    } else if (receiptDetail.isOpen) {
                      res = await CRUDFunction.update(
                        `${URL_STAGING}receipt/create`,
                        modalData
                      );

                      if (res.message !== "Mail has sent.") {
                        loading.onClose();
                        reset();
                        setButtonType("");
                        viewModal.onClose();
                        receiptDetail.onClose();
                        setReceiptDisplay({
                          receiptNum: "",
                          receiptAmount: "",
                        });
                        setLoader(false);
                        setAlert({
                          ...alert,
                          class: "show",
                          status: "error",
                          message: "Please try again.",
                        });
                        timer();
                      } else {
                        loading.onClose();
                        reset();
                        setButtonType("");
                        viewModal.onClose();
                        receiptDetail.onClose();
                        setReceiptDisplay({
                          receiptNum: "",
                          receiptAmount: "",
                        });
                        setLoader(false);
                        setAlert({
                          ...alert,
                          class: "show",
                          status: "success",
                          message: "Receipt is successfully sent to email.",
                        });
                        timer();
                      }
                    }
                  }
                }}
              >
                Create & Send
              </Button>
            </>
            <Button variant="outline" onClick={viewModal.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------------------view modal end------------------------------------------------------ */}
      {/* -----------------------------------------invoice modal--------------------------------------------------- */}
      <ModalComponent
        modalHeader="Invoice"
        trigger={invoiceDetail}
        loader={loader}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        themeCtx={themeCtx}
        inputArr={[
          {
            type: "text",
            label: "Invoice Num",
            param: "invoiceNum",
            select: [],
            disabled: invNum === undefined ? false : true,
            defaultValue: invNum,
          },
          {
            type: "date",
            label: "Invoice Date",
            param: "invoiceDate",
            select: [],
            disabled: false,
          },
        ]}
        buttonName={"View"}
        buttonName2={"Save"}
        setButtonType={setButtonType}
        setInvNum={setInvNum}
      />

      {/* --------------------------------invoice modal end------------------------------------------------------ */}
      {/* -----------------------------------------receipt modal--------------------------------------------------- */}

      <ModalComponent
        modalHeader="Receipt"
        trigger={receiptDetail}
        loader={loader}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        themeCtx={themeCtx}
        inputArr={[
          {
            type: "text",
            label: "Invoice Num",
            param: "invoiceNum",
            select: [],
            disabled: true,
            defaultValue: invNum,
          },
          {
            type: "text",
            label: "Receipt Num",
            param: "receiptNum",
            select: [],
            disabled: receiptDisplay.receiptNum === "" ? false : true,
            defaultValue: receiptDisplay.receiptNum,
          },
          {
            type: "date",
            label: "Receipt Date",
            param: "receiptDate",
            select: [],
          },
          {
            type: "text",
            label: "Receipt Amount",
            param: "receiptAmount",
            select: [],
            disabled: receiptDisplay.receiptAmount === "" ? false : true,
            defaultValue: receiptDisplay.receiptAmount,
          },
        ]}
        buttonName={"View"}
        buttonName2={"Save"}
        setButtonType={setButtonType}
        setReceiptDisplay={setReceiptDisplay}
      />
      {/* --------------------------------receipt modal end------------------------------------------------------ */}
      {/* -----------------------------------------move to modal--------------------------------------------------- */}
      <ModalComponent
        modalHeader="Move To"
        trigger={moveTraining}
        loader={loader}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        themeCtx={themeCtx}
        inputArr={[
          {
            type: "text",
            label: "Training",
            param: "trainingID",
            select: trainingNames,
          },
        ]}
        buttonName={"Move"}
      />
      {/* --------------------------------move to modal end------------------------------------------------------ */}

      <ChakraAlertDialog
        title={"Delete Trainee"}
        dialogProps={deleteMenu}
        url={`${URL_STAGING}trainee/delete/${traineeID}/${state.id}`}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        themeCtx={themeCtx}
        button={"Delete"}
      />

      <ChakraAlertDialog
        title={"Delete Trainee"}
        dialogProps={deleteWithdrawMenu}
        url={`${URL_STAGING}trainee/deletewithdraw/${traineeID}/${state.id}`}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        themeCtx={themeCtx}
        button={"Delete"}
      />

      <ChakraAlertDialog
        title={"Kick Trainee"}
        dialogProps={kickTraineeMenu}
        url={`${URL_STAGING}trainee/kick/${traineeID}/${state.id}`}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        themeCtx={themeCtx}
        button={"Kick"}
      />

      <ChakraAlertDialog
        title={"Withdraw Trainee"}
        dialogProps={withdrawMenu}
        url={[
          `${URL_STAGING}withdrawtrainee/${traineeID}`,
          JSON.stringify({
            trainee_status: "3",
            training_id: state.id,
            currentTab: tab,
          }),
        ]}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={
          "Are you sure to withdraw this candidate? You can't undo this action afterwards."
        }
        themeCtx={themeCtx}
        button={"Withdraw"}
      />

      <ChakraAlertDialog
        title={"Move to Register Tab"}
        dialogProps={registerMenu}
        url={[
          `${URL_STAGING}moveRegister/${traineeID}`,
          JSON.stringify({
            trainee_status: "3",
            training_id: state.id,
            currentTab: tab,
          }),
        ]}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={
          "Are you sure to move this candidate? You can't undo this action afterwards."
        }
        themeCtx={themeCtx}
        button={"Move"}
      />

      <ChakraAlertDialog
        title={"Register Trainee"}
        dialogProps={joinMenu}
        url={[
          `${URL_STAGING}registerstudent/${traineeID}`,
          JSON.stringify({
            trainee_status: "2",
            training_id: state.id,
            currentTab: tab,
          }),
        ]}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={
          "Are you sure to register this participant? You can't undo this action afterwards."
        }
        themeCtx={themeCtx}
        button={"Register"}
      />

      <ErrorsDialog
        title={"Errors"}
        dialogProps={errorProps}
        body={errorAlert}
      />

      <Modal size="xs" isOpen={loading.isOpen}>
        <ModalOverlay />
        <ModalContent my="auto">
          <ModalBody>
            <Center>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size="xl"
        isOpen={classroomModal.isOpen}
        onClose={() => {
          resetField("classroomEnrollCode", {
            defaultValue: "",
          });
          classroomModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>CLASSROOM AUTOCREATE</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(classroomSubmit)}>
              <FormControl mb="10px">
                <FormLabel fontWeight="bold">Course Name</FormLabel>
                <Box
                  h="40px"
                  pl="15px"
                  py="7px"
                  borderRadius="10px"
                  borderWidth="2px"
                >
                  {state.course_name}
                </Box>
              </FormControl>

              {state.course_id === 9 && (
                <FormControl mb="10px">
                  <FormLabel fontWeight="bold">HC Package</FormLabel>
                  <Select defaultValue="" {...register("hcPackageClassroom")}>
                    <option value="" disabled>
                      Select package
                    </option>
                    <option value="a">
                      Package A - Shariah&Fatwa and Malaysian Standard
                    </option>
                    <option value="b">Package B - MPPHM & MHMS</option>
                    <option value="c">
                      Package C - Halal Legislation and Critical Ingredient
                    </option>
                    <option value="d">Full Package</option>
                  </Select>
                </FormControl>
              )}

              <FormControl mb="10px">
                <FormLabel fontWeight="bold">Training Name</FormLabel>
                <Box
                  h="40px"
                  pl="15px"
                  py="7px"
                  borderRadius="10px"
                  borderWidth="2px"
                >
                  {state.name}
                </Box>
              </FormControl>

              <FormControl mb="10px">
                <FormLabel fontWeight="bold">Enroll Code</FormLabel>
                <Input
                  placeholder="enroll code / entry code"
                  {...register("classroomEnrollCode")}
                ></Input>
              </FormControl>

              <HStack>
                <FormControl mb="10px">
                  <FormLabel fontWeight="bold">Start Date</FormLabel>
                  <Box>{state.date[0].startDate}</Box>
                </FormControl>

                <FormControl mb="10px">
                  <FormLabel fontWeight="bold">End Date</FormLabel>
                  <Box>{state.date[state.date.length - 1].endDate}</Box>
                </FormControl>
              </HStack>

              <ModalFooter>
                <Button
                  type="submit"
                  bg="#33945f"
                  color="white"
                  _hover={{ bg: "green.600" }}
                >
                  Create Classroom
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* -------------------------------------------------------------GENERATE DOCUMENT MODAL-------------------------------------------------- */}
      <Modal
        size="4xl"
        isOpen={generateModal.isOpen}
        onClose={generateModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(generateSubmit)}>
            <ModalHeader
              bg={themeCtx.mode ? "#1b1b1b" : "#f1f1f1"}
              borderTopRadius={8}
              alignContent="center"
            >
              {groupType}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p="30px">
              <>
                <FormControl>
                  <FormLabel>Employer Code</FormLabel>
                  <Input
                    placeholder="Fill in if applicable"
                    {...register("employerCode")}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Approval No</FormLabel>
                  <Input
                    placeholder="Fill in if applicable"
                    {...register("approvalNo")}
                  />
                </FormControl>
              </>

              {data &&
                data.map((data) => (
                  <Stack mt="10px">
                    <Checkbox
                      style={{ textTransform: "capitalize" }}
                      {...register("groupModalCheckbox")}
                      value={data.trainee_id}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {data.company.company_name}
                      </span>
                      {": "}
                      {data.trainee_name.toLowerCase()}
                    </Checkbox>
                  </Stack>
                ))}
            </ModalBody>

            <ModalFooter>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  type="submit"
                  bg="#33945f"
                  color="white"
                  onClick={() => {
                    setButtonType("view");
                  }}
                  // variant="ghost"
                  mr={3}
                >
                  View
                </Button>
                &nbsp;
                <Button variant="outline" onClick={generateModal.onClose}>
                  Close
                </Button>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* -------------------------------------------------------------end GENERATE DOCUMENT MODAL-------------------------------------------------- */}

      {/* -------------------------------------------------------------SELECT COMPANY MODAL-------------------------------------------------- */}
      <Modal
        size="lg"
        isOpen={companySelect.isOpen}
        onClose={companySelect.onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent p="10px">
          <ModalHeader>Filter by Companies</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <CheckboxGroup
              defaultValue={summaryComp}
              onChange={(e) => {
                setSummaryComp(e);
              }}
            >
              <Stack spacing={[1, 5]} direction="column">
                {compArr.map((item, index) => (
                  <Checkbox value={item.company_id.toString()}>
                    {item.company_name}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                handleFilterSummary();
                companySelect.onClose();
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export { TraineeAdmin };
