import {
  Heading,
  Container,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Text,
  Center,
  Stack,
  Textarea,
  Box,
  AspectRatio,
  Image,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useToast,
  Select,
  Link,
  FormHelperText,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { BiDotsVerticalRounded } from "react-icons/bi";
import styled from "styled-components";
import {
  ReactTable,
  AlertPop,
  ModalComponent,
  ChakraAlertDialog,
  ErrorsDialog,
  Excel,
} from "../../../components";
import { MdPreview } from "react-icons/md";
import { useForm } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import ThemeContext from "../../../functions/ThemeContext";
import { findPostcode, getStates } from "malaysia-postcodes";

const Styles = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :nth-child(1) {
        width: 80px;
      }
      :nth-child(2) {
        width: 350px;
      }
      :nth-child(3) {
        width: 50px;
      }
      :nth-child(4) {
        width: 100px;
      }
      :nth-child(5) {
        width: 100px;
      }
    }

    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #bdbdbd;
      text-align: left;
      :nth-child(1),
      :nth-child(4),
      :nth-child(5),
      :last-child {
        text-align: center;
      }
      :last-child {
        width: 120px;
        border-right: 0;
      }
    }
  }
`;

export default function CompanyAdmin() {
  const addCompany = useDisclosure();
  const editCompany = useDisclosure();
  const dialogProps = useDisclosure();
  const viewModal = useDisclosure();
  const toast = useToast();
  const [data, setData] = useState(
    []
    // { tableLoader: false, rowData: [], totalPages: 0, totalData: 0 }
  );
  const [loader, setLoader] = useState();
  const [editData, setEditData] = useState([]);
  const [viewData, setViewData] = useState(false);
  const [companyID, setCompanyID] = useState("");
  const [showAddress, setShowAddress] = useState({
    address: "",
    postcode: "",
    state: "",
    country: "Malaysia",
  });
  const [selectedImage, setSelectedImage] = useState();
  const [editImage, setEditImage] = useState(false);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const btnRef = React.useRef();

  const [bigData, setBigData] = useState([]);
  const [excel, setExcel] = useState(false);
  const [err, setErr] = useState({
    name: false,
    ssm: false,
    address: false,
    postcode: false,
    state: false,
    picname: false,
    picemail: false,
    picposition: false,
    picphone: false,
  });
  const errorProps = useDisclosure();
  const [errorAlert, setErrorAlert] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const themeCtx = useContext(ThemeContext);
  const countryCodes = require("country-codes-list");
  const [countries, setCountries] = useState([]);
  const [changePhone, setChangePhone] = useState(false);
  const [MyStates, setMyStates] = useState([]);
  const [schemeList, setSchemeList] = useState([]);

  useEffect(() => {
    setLoader(true);
    fetchData();
    fetchCountry();
  }, []);

  const fetchData = async () => {
    // const res = await CRUDFunction.get(`${URL_STAGING}company?page=${pageNo}`);
    const res = await CRUDFunction.get(`${URL_STAGING}company`);
    if (res) {
      setData(res);
      setLoader(false);
      // setData({
      //   rowData: res.data,
      //   tableLoader: false,
      //   totalPages: res.last_page,
      //   totalData: res.total,
      // });
    }
  };

  const fetchCountry = async () => {
    let listName = countryCodes.customList("countryNameEn", "{countryCode}");
    let listCode = countryCodes.customList(
      "countryNameEn",
      "+{countryCallingCode}"
    );
    let key = Object.keys(listName);
    let name = Object.values(listName);
    let code = Object.values(listCode);
    let tempList = [];
    for (let i = 0; i < key.length; i++) {
      tempList.push({
        value: key[i],
        label: key[i],
        name: name[i],
        code: code[i],
      });
    }
    setCountries(tempList);
    setMyStates(getStates);
  };

  const { register, handleSubmit, reset, resetField } = useForm();

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
      errorProps.onClose();
    }, 5000);
  };

  const resetErr = () => {
    setErr({
      name: false,
      ssm: false,
      address: false,
      postcode: false,
      state: false,
      picname: false,
      // picic: false,
      picemail: false,
      picposition: false,
      picphone: false,
    });
  };

  const clearErr = (e) => {
    if (e.target.value !== "") {
      if (e.target.id === "companyname") {
        setErr((err) => ({ ...err, name: false }));
      }
      if (e.target.id === "companyssm") {
        setErr((err) => ({ ...err, ssm: false }));
      }
      if (e.target.id === "companyaddress") {
        setErr((err) => ({ ...err, address: false }));
      }
      if (e.target.id === "companypostcode") {
        setErr((err) => ({ ...err, postcode: false }));
      }
      if (e.target.id === "companystate") {
        setErr((err) => ({ ...err, state: false }));
      }
      if (e.target.id === "picname") {
        setErr((err) => ({ ...err, picname: false }));
      }
      // if (e.target.id === "picic") {
      //   setErr((err) => ({ ...err, picic: false }));
      // }
      if (e.target.id === "picemail") {
        setErr((err) => ({ ...err, picemail: false }));
      }
      if (e.target.id === "picposition") {
        setErr((err) => ({ ...err, picposition: false }));
      }
      if (e.target.id === "picphone") {
        setErr((err) => ({ ...err, picphone: false }));
      }
    }
  };

  const checkPostcodes = (e) => {
    if (e.target.value !== null && e.target.value.length === 5) {
      var location = findPostcode(e.target.value);
      if (location.found === true) {
        resetField("companyCity", {
          defaultValue: location.city,
        });
        resetField("companyState", {
          defaultValue: location.state,
        });
        resetField("companyCountry", {
          defaultValue: "Malaysia",
        });
      } else {
        resetField("companyCity", {
          defaultValue: "",
        });
        resetField("companyState", {
          defaultValue: "",
        });
        resetField("companyCountry", {
          defaultValue: "Malaysia",
        });
      }
    } else {
      resetField("companyCity", {
        defaultValue: "",
      });
      resetField("companyState", {
        defaultValue: "",
      });
      resetField("companyCountry", {
        defaultValue: "",
      });
    }
  };

  const onSubmit = async (data) => {
    if (data && schemeList.length > 0) {
      if (data.companyName === "") {
        setErr((err) => ({ ...err, name: true }));
      } else {
        setErr((err) => ({ ...err, name: false }));
      }

      if (data.companySSM === "") {
        setErr((err) => ({ ...err, ssm: true }));
      } else {
        setErr((err) => ({ ...err, ssm: false }));
      }

      if (data.companyAddress === "") {
        setErr((err) => ({ ...err, address: true }));
      } else {
        setErr((err) => ({ ...err, address: false }));
      }

      if (data.companyPostcode === "") {
        setErr((err) => ({ ...err, postcode: true }));
      } else {
        setErr((err) => ({ ...err, postcode: false }));
      }

      if (data.companyState === "") {
        setErr((err) => ({ ...err, state: true }));
      } else {
        setErr((err) => ({ ...err, state: false }));
      }

      if (data.companyPicname === "") {
        setErr((err) => ({ ...err, picname: true }));
      } else {
        setErr((err) => ({ ...err, picname: false }));
      }

      if (data.companyPicemail === "") {
        setErr((err) => ({ ...err, picemail: true }));
      } else {
        setErr((err) => ({ ...err, picemail: false }));
      }

      if (data.companyPicposition === "") {
        setErr((err) => ({ ...err, picposition: true }));
      } else {
        setErr((err) => ({ ...err, picposition: false }));
      }

      if (data.companyPicphone === "") {
        setErr((err) => ({ ...err, picphone: true }));
      } else {
        setErr((err) => ({ ...err, picphone: false }));
      }

      const formData = new FormData();
      formData.append("company_name", data.companyName);
      formData.append("company_ssm", data.companySSM);
      let combineAddress = [
        {
          address: data.companyAddress,
          postcode: data.companyPostcode,
          city: data.companyCity,
          state: data.companyState,
          country: data.companyCountry,
        },
      ];
      formData.append("company_address", JSON.stringify(combineAddress));
      formData.append("company_scheme", JSON.stringify(schemeList));
      formData.append("company_picname", data.companyPicname);
      if (addCompany.isOpen) {
        formData.append(
          "company_picphone",
          data.companyPicCodePhone + data.companyPicphone.toString()
        );
      } else {
        if (editData.company_picphone !== "") {
          if (!changePhone) {
            formData.append("company_picphone", editData.company_picphone);
          } else {
            formData.append(
              "company_picphone",
              data.companyPicCodePhone + data.companyPicphone.toString()
            );
          }
        } else {
          formData.append(
            "company_picphone",
            data.companyPicCodePhone + data.companyPicphone.toString()
          );
        }
      }
      formData.append("company_picemail", data.companyPicemail);
      formData.append("company_picposition", data.companyPicposition);
      if (data.companyFinanceEmail !== "") {
        formData.append("company_finance_email", data.companyFinanceEmail);
      } else {
        formData.append("company_finance_email", "");
      }

      if (data.companyImage.length !== 0) {
        formData.append("company_image", data.companyImage[0]);
      }

      let res;
      if (addCompany.isOpen) {
        if (excel) {
          let companyName = [],
            companySSM = [],
            companyAddress = [],
            companyPostcode = [],
            companyState = [],
            picName = [],
            // picIC = [],
            picEmail = [],
            picPosition = [],
            picPhoneNo = [],
            financeEmail = [];
          combineAddress = [];

          for (let i = 0; i < bigData.length; i++) {
            companyName.push(bigData[i]["COMPANY NAME"]);
            companySSM.push(bigData[i]["COMPANY SSM"]);
            companyAddress.push(bigData[i]["COMPANY ADDRESS"]);
            companyPostcode.push(bigData[i]["POSTCODE"]);
            companyState.push(bigData[i]["STATE"]);
            picName.push(bigData[i]["PIC NAME"]);
            // picIC.push(bigData[i]["PIC IC"]);
            picEmail.push(bigData[i]["PIC EMAIL"]);
            picPosition.push(bigData[i]["PIC POSITION"]);
            picPhoneNo.push(bigData[i]["PIC PHONE NUMBER"]);
            financeEmail.push(bigData[i]["FINANCE EMAIL"]);
          }
          for (let i = 0; i < companyAddress.length; i++) {
            combineAddress[i] = JSON.stringify([
              {
                address: companyAddress[i],
                postcode: companyPostcode[i],
                state: companyState[i],
              },
            ]);
          }
          const formData = new FormData();
          formData.append("company_name", JSON.stringify(companyName));
          formData.append("company_ssm", JSON.stringify(companySSM));
          formData.append("company_address", JSON.stringify(combineAddress));
          formData.append("company_picname", JSON.stringify(picName));
          formData.append("company_picphone", JSON.stringify(picPhoneNo));
          // formData.append("company_picic", JSON.stringify(picIC));
          formData.append("company_picemail", JSON.stringify(picEmail));
          formData.append("company_picposition", JSON.stringify(picPosition));
          formData.append(
            "company_finance_email",
            JSON.stringify(financeEmail)
          );

          res = await CRUDFunction.create(
            `${URL_STAGING}companyExcel`,
            formData
          );
        } else {
          res = await CRUDFunction.create(`${URL_STAGING}company`, formData);
        }

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          removeSelectedImage();
          setExcel(false);
          setData(res);
          reset();
          resetErr();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully created.",
          });
          timer();
          addCompany.onClose();
        }
      } else if (editCompany.isOpen) {
        const res = await CRUDFunction.update(
          `${URL_STAGING}company/update/${companyID}`,
          formData
        );

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          removeSelectedImage();
          setData(res);
          reset();
          resetErr();
          setEditImage(false);
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully updated.",
          });
          timer();
          editCompany.onClose();
        }
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "COMPANY",
        accessor: (data) => {
          return [data.company_name, data.company_ssm];
        },
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li style={{ fontWeight: "700" }}>{value[0].toUpperCase()}</li>
              <li>{value[1]}</li>
            </ul>
          );
        },
      },

      {
        Header: "PIC INFORMATION",
        accessor: (data) => {
          let arr = data.company_picname.toLowerCase().split(" ");
          for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          }
          var picName = arr.join(" ");
          return {
            name: picName,
            phone: data.company_picphone,
            email: data.company_picemail,
          };
        },
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li style={{ textDecoration: "underline" }}>{value.name}</li>
              <li style={{ fontStyle: "italic" }}>{value.phone}</li>
              <li>{value.email}</li>
            </ul>
          );
        },
      },
      {
        Header: "TOTAL PARTICIPANTS",
        accessor: "trainees.length",
      },
      {
        Header: "ACTIONS",
        accessor: "company_id",
        disableSortBy: true,
        Cell: ({ cell }) => (
          <>
            <Menu>
              <MenuButton
                as={Button}
                bgColor="none"
                _hover={{ bg: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                _expanded={{ bg: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                _click={{ bg: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                align="center"
                variant="ghost"
                rightIcon={<BiDotsVerticalRounded boxsize={5} />}
              ></MenuButton>
              <MenuList minW="100px" bgColor={themeCtx.mode && "#2b2b2b"}>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bgColor: themeCtx.mode && "#1b1b1b" }}
                  icon={<EditIcon boxsize={5} color="#14804a" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}company/${cell.value}`
                    );
                    if (res) {
                      setEditData(res.company);
                      setShowAddress(
                        JSON.parse(res.company.company_address)[0]
                      );
                      setCompanyID(cell.value);
                      setSchemeList(JSON.parse(res.company.company_scheme));
                      editCompany.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bgColor: themeCtx.mode && "#1b1b1b" }}
                  icon={<MdPreview fontSize="20px" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}company/${cell.value}`
                    );
                    if (res) {
                      setViewData(res);
                      setCompanyID(cell.value);
                      viewModal.onOpen();
                    }
                  }}
                >
                  View
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bgColor: themeCtx.mode && "#1b1b1b" }}
                  icon={<DeleteIcon boxSize={5} color="#d12953" />}
                  onClick={() => {
                    setCompanyID(cell.value);
                    dialogProps.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      },
    ],
    []
  );

  const imageChange = (e) => {
    if (editCompany.isOpen) {
      setEditImage(true);
    }

    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  return (
    <div>
      {/* -----------------------modal view-------------------------------- */}
      <ModalComponent
        themeCtx={themeCtx}
        trigger={viewModal}
        modalHeader="Company Details"
        viewData={viewData}
        viewName={[
          "company_name",
          "company_ssm",
          "company_address",
          "company_scheme",
          "company_finance_email",
          "company_picname",
          "company_picphone",
          "company_picemail",
          "company_picposition",
          "company_revenue",
        ]}
      />
      {/* --------------------------modal view end-------------------------- */}

      {/* -----------------add company drawer------------------- */}
      <Drawer
        isOpen={addCompany.isOpen}
        placement="center"
        onClose={() => {
          removeSelectedImage();
          setEditImage(false);
          reset();
          resetErr();
          addCompany.onClose();
        }}
        finalFocusRef={btnRef}
        size="md"
        style={{ overflowY: "auto" }}
      >
        <DrawerOverlay />
        <DrawerContent
          overflowY="auto"
          bgColor={themeCtx.mode && "#2b2b2b"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <Stack direction={"column"} align="center">
              <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
                ADD COMPANY
              </DrawerHeader>
              <b>UPLOAD EXCEL FILE</b>
              <Excel handleBigData={setBigData} handleExcel={setExcel} />
            </Stack>

            <DrawerBody>
              {excel === false ? (
                <>
                  <Text align="center" fontWeight="bold">
                    OR
                  </Text>
                  <b>Upload Company Image</b>
                  <Container>
                    <AspectRatio ratio={4 / 3}>
                      <Box
                        bgColor={themeCtx.mode ? "#1b1b1b" : "gray.100"}
                        borderColor="gray.300"
                        borderStyle="dashed"
                        borderWidth="2px"
                        rounded="md"
                        role="group"
                      >
                        <Stack
                          display="flex"
                          alignItems="center"
                          justify="center"
                          mb="20px"
                        >
                          {selectedImage && (
                            <div
                              style={{
                                mt: "50",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Image
                                height="400px"
                                width="500px"
                                src={URL.createObjectURL(selectedImage)}
                                style={{ maxWidth: "100%", maxHeight: "30%" }}
                                alt="Thumb"
                              />
                            </div>
                          )}
                        </Stack>
                      </Box>
                    </AspectRatio>
                    <br />
                    <input
                      type={"file"}
                      class="custom-image-input"
                      accept={"image/*"}
                      {...register("companyImage")}
                      onChange={imageChange}
                    />
                  </Container>

                  <FormControl isInvalid={err.name} pt="20px">
                    <FormLabel fontWeight="bold">
                      Company Name <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="companyname"
                      placeholder="Company Name"
                      {...register("companyName")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.name && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.ssm} pt="20px">
                    <FormLabel fontWeight="bold">
                      Company SSM <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="companyssm"
                      placeholder="Company SSM"
                      {...register("companySSM")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.ssm && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.address} pt="20px">
                    <FormLabel fontWeight="bold">
                      Company Address <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Textarea
                      resize="none"
                      h="120px"
                      id="companyaddress"
                      placeholder="Company Address"
                      {...register("companyAddress")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.address && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <HStack>
                    <Box w="30%">
                      <FormControl isInvalid={err.postcode} pt="20px">
                        <FormLabel fontWeight="bold">
                          Postcode <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          id="companypostcode"
                          placeholder="Postcode"
                          {...register("companyPostcode")}
                          onBlur={(e) => {
                            checkPostcodes(e);
                            clearErr();
                          }}
                        />
                        <FormHelperText color="transparent">
                          Type in the state if it doesn't
                        </FormHelperText>
                      </FormControl>
                      {err.postcode && (
                        <span style={{ color: "red" }}>Required</span>
                      )}
                    </Box>
                    <Box w="30%">
                      <FormControl isInvalid={err.city} pt="20px">
                        <FormLabel fontWeight="bold">
                          City <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          id="companycity"
                          placeholder="City"
                          {...register("companyCity")}
                          onBlur={clearErr}
                        />
                        <FormHelperText color="transparent">
                          Type in the state if it doesn't
                        </FormHelperText>
                      </FormControl>
                      {err.city && (
                        <span style={{ color: "red" }}>Required</span>
                      )}
                    </Box>
                    <Box w="40%">
                      <FormControl isInvalid={err.state} pt="20px">
                        <FormLabel fontWeight="bold">
                          State <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          list="MyStates"
                          id="companystate"
                          placeholder="State"
                          {...register("companyState")}
                          onBlur={clearErr}
                        />
                        <datalist id="MyStates">
                          {MyStates.map((option) => (
                            <option key={option} value={option} />
                          ))}
                        </datalist>
                        <FormHelperText>
                          Type in the state if it doesn't exist in the list.
                        </FormHelperText>
                      </FormControl>
                      {err.state && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </Box>
                  </HStack>
                  <FormControl mt="10px" isRequired>
                    <FormLabel fontWeight="bold">Country</FormLabel>
                    <Select
                      defaultValue="Malaysia"
                      {...register("companyCountry")}
                    >
                      {countries.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl mt="10px" isRequired>
                    <FormLabel fontWeight="bold">Schemes</FormLabel>
                    <Menu closeOnSelect={false}>
                      <MenuButton
                        as={Button}
                        minWidth="100%"
                        borderColor="#33945f"
                        borderWidth="2px"
                      >
                        {schemeList && schemeList.length > 0
                          ? schemeList.length + " schemes selected"
                          : "Choose 1 or more schemes"}
                      </MenuButton>
                      <MenuList>
                        <MenuOptionGroup
                          type="checkbox"
                          onChange={(e) => {
                            setSchemeList(e);
                          }}
                        >
                          <MenuItemOption value="Food & Beverage">
                            Food & Beverage
                          </MenuItemOption>
                          <MenuItemOption value="Cosmetics">
                            Cosmetics
                          </MenuItemOption>
                          <MenuItemOption value="Pharmaceuticals">
                            Pharmaceuticals
                          </MenuItemOption>
                          <MenuItemOption value="Consumer Goods">
                            Consumer Goods
                          </MenuItemOption>
                          <MenuItemOption value="Food Premises">
                            Food Premises
                          </MenuItemOption>
                          <MenuItemOption value="Slaughter House">
                            Slaughter House
                          </MenuItemOption>
                          <MenuItemOption value="Logistics">
                            Logistics
                          </MenuItemOption>
                          <MenuItemOption value="OEM">OEM</MenuItemOption>
                          <MenuItemOption value="Medical Devices">
                            Medical Devices
                          </MenuItemOption>
                          <MenuItemOption value="Education">
                            Education
                          </MenuItemOption>
                          <MenuItemOption value="Others">Others</MenuItemOption>
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                  </FormControl>
                  <FormControl pt="20px">
                    <FormLabel fontWeight="bold">Finance Email</FormLabel>
                    <Input
                      id="finance_email"
                      type="email"
                      placeholder="Finance Email"
                      {...register("companyFinanceEmail")}
                    />
                  </FormControl>
                  <FormControl isInvalid={err.picname} pt="20px">
                    <FormLabel fontWeight="bold">
                      PIC Name <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="picname"
                      type="text"
                      placeholder="PIC Name"
                      {...register("companyPicname")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.picname && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}

                  <FormControl isInvalid={err.picemail} pt="20px">
                    <FormLabel fontWeight="bold">
                      PIC Email <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="picemail"
                      type="email"
                      placeholder="PIC Email"
                      {...register("companyPicemail")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.picemail && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.picposition} pt="20px">
                    <FormLabel fontWeight="bold">
                      PIC Position <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="picposition"
                      type="text"
                      placeholder="PIC Position"
                      {...register("companyPicposition")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.picposition && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.picphone} pt="20px">
                    <FormLabel fontWeight="bold">
                      PIC Phone Number <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <HStack>
                      <Select
                        defaultValue="+60"
                        {...register("companyPicCodePhone")}
                      >
                        {countries
                          .sort((a, b) => {
                            if (a.label.toLowerCase() < b.label.toLowerCase())
                              return -1;
                            if (a.label.toLowerCase() > b.label.toLowerCase())
                              return 1;
                            return 0;
                          })
                          .map((item) => (
                            <option
                              style={{ width: "100px" }}
                              value={item.code}
                            >
                              {item.value} {item.code}
                            </option>
                          ))}
                      </Select>
                      <Input
                        id="picphone"
                        type="number"
                        placeholder="PIC Phone Number"
                        {...register("companyPicphone")}
                        onBlur={clearErr}
                      />
                    </HStack>
                  </FormControl>
                  {err.picphone && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </>
              ) : (
                ""
              )}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Add
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    removeSelectedImage();
                    reset();
                    resetErr();
                    addCompany.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* -----------------add company drawer end------------------- */}

      {/* -----------------edit company drawer------------------- */}
      <Drawer
        isOpen={editCompany.isOpen}
        placement="center"
        onClose={() => {
          setEditImage(false);
          removeSelectedImage();
          reset();
          resetErr();
          setChangePhone(false);
          editCompany.onClose();
        }}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent
          overflowY="auto"
          bgColor={themeCtx.mode && "#2b2b2b"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              EDIT COMPANY
            </DrawerHeader>
            <DrawerBody>
              <Container my="10px">
                <Text>Current Company Image</Text>
                <AspectRatio ratio={4 / 3}>
                  <Box
                    bgColor={themeCtx.mode ? "#1b1b1b" : "gray.100"}
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="md"
                    role="group"
                  >
                    <Stack display="flex" alignItems="center" justify="center">
                      <Image
                        height="400px"
                        width="500px"
                        src={
                          editImage
                            ? URL.createObjectURL(selectedImage)
                            : `http://localhost:8000/uploads/${editData.company_image}`
                        }
                      />
                    </Stack>
                  </Box>
                </AspectRatio>
              </Container>
              <HStack>
                <Box w="60%" align="left">
                  <Text mt="10px" color="green">
                    &nbsp;&nbsp;&nbsp;&nbsp;Upload new company picture:
                  </Text>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type={"file"}
                    class="custom-image-input"
                    accept={"image/*"}
                    {...register("companyImage")}
                    onChange={imageChange}
                  />
                </Box>
                {editData.company_image ? (
                  <Box w="40%" pb="35px">
                    <Popover size="sm">
                      <PopoverTrigger>
                        <Text
                          mt="10px"
                          color="red"
                          _hover={{ cursor: "pointer" }}
                        >
                          Remove company image
                        </Text>
                      </PopoverTrigger>
                      <PopoverContent p="5px" textAlign="center">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Confirmation!</PopoverHeader>
                        <PopoverBody>
                          Are you sure? You can't undo this action afterwards.
                        </PopoverBody>
                        <Center>
                          <Button
                            w="50%"
                            bgColor="red"
                            _hover={{ bgColor: "red.500" }}
                            color="white"
                            size="sm"
                            onClick={() => {
                              setEditData({
                                ...editData,
                                company_image: false,
                              });
                              setSelectedImage("");
                            }}
                          >
                            Remove
                          </Button>
                        </Center>
                      </PopoverContent>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
              </HStack>

              <FormControl isInvalid={err.name} mt="20px">
                <FormLabel fontWeight="bold">
                  Company Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="subject"
                  placeholder="Company Name"
                  defaultValue={editData.company_name}
                  {...register("companyName")}
                />
              </FormControl>
              {err.name && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.ssm} mt="20px">
                <FormLabel fontWeight="bold">
                  Company SSM <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="subject"
                  placeholder="Company SSM"
                  defaultValue={editData.company_ssm}
                  {...register("companySSM")}
                />
              </FormControl>
              {err.ssm && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.address} mt="20px">
                <FormLabel fontWeight="bold">
                  Company Address <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Textarea
                  resize="none"
                  h="120px"
                  id="company_address"
                  placeholder="Company Address"
                  defaultValue={showAddress.address}
                  {...register("companyAddress")}
                />
              </FormControl>
              {err.address && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <HStack>
                <Box w="30%">
                  <FormControl isInvalid={err.postcode} mt="20px">
                    <FormLabel fontWeight="bold">
                      Postcode <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="companyPostcode"
                      placeholder="Postcode"
                      defaultValue={showAddress.postcode}
                      {...register("companyPostcode")}
                      onBlur={checkPostcodes}
                    />
                    <FormHelperText color="transparent">
                      Type in the state if it doesn't
                    </FormHelperText>
                  </FormControl>
                  {err.postcode && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </Box>
                <Box w="30%">
                  <FormControl isInvalid={err.city} mt="20px">
                    <FormLabel fontWeight="bold">
                      City <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="companyCity"
                      placeholder="City"
                      defaultValue={showAddress.city}
                      {...register("companyCity")}
                    />
                    <FormHelperText color="transparent">
                      Type in the state if it doesn't
                    </FormHelperText>
                  </FormControl>
                  {err.city && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </Box>
                <Box w="40%">
                  <FormControl isInvalid={err.state} mt="20px">
                    <FormLabel fontWeight="bold">
                      State <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      list="MyStates"
                      id="companyState"
                      placeholder="State"
                      defaultValue={showAddress.state}
                      {...register("companyState")}
                    />
                    <datalist id="MyStates">
                      {MyStates.map((option) => (
                        <option key={option} value={option} />
                      ))}
                    </datalist>
                    <FormHelperText>
                      Type in the state if it doesn't exist in the list.
                    </FormHelperText>
                  </FormControl>
                  {err.state && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </Box>
              </HStack>
              <FormControl mt="10px" isRequired>
                <FormLabel fontWeight="bold">Country</FormLabel>
                <Select
                  defaultValue={showAddress.country}
                  {...register("companyCountry")}
                >
                  {countries.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mt="10px" isRequired>
                <FormLabel fontWeight="bold">Schemes</FormLabel>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    minWidth="100%"
                    borderColor="#33945f"
                    borderWidth="2px"
                  >
                    {schemeList && schemeList.length > 0
                      ? schemeList.length + " schemes selected"
                      : "Choose 1 or more schemes"}
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup
                      type="checkbox"
                      defaultValue={schemeList}
                      onChange={(e) => {
                        setSchemeList(e);
                      }}
                    >
                      <MenuItemOption value="Food & Beverage">
                        Food & Beverage
                      </MenuItemOption>
                      <MenuItemOption value="Cosmetics">
                        Cosmetics
                      </MenuItemOption>
                      <MenuItemOption value="Pharmaceuticals">
                        Pharmaceuticals
                      </MenuItemOption>
                      <MenuItemOption value="Consumer Goods">
                        Consumer Goods
                      </MenuItemOption>
                      <MenuItemOption value="Food Premises">
                        Food Premises
                      </MenuItemOption>
                      <MenuItemOption value="Slaughter House">
                        Slaughter House
                      </MenuItemOption>
                      <MenuItemOption value="Logistics">
                        Logistics
                      </MenuItemOption>
                      <MenuItemOption value="OEM">OEM</MenuItemOption>
                      <MenuItemOption value="Medical Devices">
                        Medical Devices
                      </MenuItemOption>
                      <MenuItemOption value="Education">
                        Education
                      </MenuItemOption>
                      <MenuItemOption value="Others">Others</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </FormControl>
              <FormControl pt="20px">
                <FormLabel fontWeight="bold">Finance Email</FormLabel>
                <Input
                  id="finance_email"
                  type="email"
                  placeholder="Finance Email"
                  defaultValue={
                    editData.company_finance_email !== "-"
                      ? editData.company_finance_email
                      : ""
                  }
                  {...register("companyFinanceEmail")}
                />
              </FormControl>
              <FormControl isInvalid={err.picname} mt="20px">
                <FormLabel fontWeight="bold">
                  PIC Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="pic_name"
                  type="text"
                  placeholder="PIC Name"
                  defaultValue={editData.company_picname}
                  {...register("companyPicname")}
                />
              </FormControl>
              {err.picname && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.picemail} mt="20px">
                <FormLabel fontWeight="bold">
                  PIC Email <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="pic_email"
                  type="text"
                  placeholder="PIC Email"
                  defaultValue={editData.company_picemail}
                  {...register("companyPicemail")}
                />
              </FormControl>
              {err.picemail && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.picposition} mt="20px">
                <FormLabel fontWeight="bold">
                  PIC Position <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="pic_position"
                  type="text"
                  placeholder="PIC Position"
                  defaultValue={editData.company_picposition}
                  {...register("companyPicposition")}
                />
              </FormControl>
              {err.picposition && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.picphone} mt="20px">
                <FormLabel fontWeight="bold">
                  PIC Phone Number <span style={{ color: "red" }}>*</span>
                </FormLabel>
                {!editData.company_picphone || changePhone ? (
                  <>
                    <HStack>
                      <Select
                        defaultValue="+60"
                        {...register("companyPicCodePhone")}
                      >
                        {countries
                          .sort((a, b) => {
                            if (a.label.toLowerCase() < b.label.toLowerCase())
                              return -1;
                            if (a.label.toLowerCase() > b.label.toLowerCase())
                              return 1;
                            return 0;
                          })
                          .map((item) => (
                            <option
                              style={{ width: "100px" }}
                              value={item.code}
                            >
                              {item.value} {item.code}
                            </option>
                          ))}
                      </Select>
                      <Input
                        id="pic_phoneNo"
                        type="number"
                        placeholder="PIC Phone Number"
                        {...register("companyPicphone")}
                      />
                    </HStack>
                    {editData.company_picphone && (
                      <Link
                        color="red"
                        fontSize="15px"
                        onClick={() => setChangePhone(false)}
                      >
                        Cancel
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    <Box
                      h="45px"
                      _hover={{ cursor: "not-allowed" }}
                      borderRadius="10px"
                      borderWidth="2px"
                      mb="7px"
                      pl="15px"
                      py="7px"
                      fontSize="17px"
                    >
                      {editData.company_picphone}
                    </Box>
                    {editData.company_picphone && (
                      <Link
                        color="blue"
                        fontSize="15px"
                        onClick={() => setChangePhone(true)}
                      >
                        Click here to change phone number
                      </Link>
                    )}
                  </>
                )}
              </FormControl>
              {err.picphone && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    setEditImage(false);
                    removeSelectedImage();
                    reset();
                    resetErr();
                    setChangePhone(false);
                    editCompany.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      <ErrorsDialog
        title={"Errors"}
        dialogProps={errorProps}
        body={errorAlert}
      />
      {/* -----------------edit company drawer end------------------- */}
      {alert.message ? (
        <AlertPop
          width="30%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Container maxW="90%" mt="20px">
        <Heading color={themeCtx.mode && "white"}>COMPANY</Heading>
      </Container>
      <Container
        maxW="90%"
        overflowY="auto"
        borderRadius={10}
        bgColor={themeCtx.mode ? "#2b2b2b" : "white"}
        m="20px auto"
        display="grid"
      >
        <div style={{ position: "relative" }}>
          <Button
            leftIcon={<AddIcon />}
            pos="absolute"
            fontSize="14px"
            right="4"
            w="200px"
            bottom="-20"
            px="6"
            bg="#33945f"
            _hover={{ bg: "green.700" }}
            color="white"
            onClick={addCompany.onOpen}
          >
            Add Company
          </Button>
        </div>
        <ChakraAlertDialog
          title={"Delete Company"}
          dialogProps={dialogProps}
          url={[`${URL_STAGING}company/${companyID}`]}
          setAlert={setAlert}
          alert={alert}
          setData={setData}
          body={"Are you sure? You can't undo this action afterwards."}
          themeCtx={themeCtx}
          button={"Delete"}
        />
        <Styles>
          <ReactTable
            modulePage="company"
            columns={columns}
            data={data}
            tableLoader={loader}
            themeCtx={themeCtx}
          />
        </Styles>
      </Container>
    </div>
  );
}
